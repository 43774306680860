import * as actionTypes from "./actionTypes";
import { openSnackbarAction } from "@icarius-common/snackbar/actions";
import { getErrorStringFromError } from "@icarius-localization/errors";
import {
  getAnnotationsAPI,
  deleteAnnotationAPI,
  createAnnotationAPI,
  updateAnnotationAPI,
} from "@icarius-connection/api";
import { getLocalizedErrorString } from "@icarius-localization/strings";

export const clearAnnotationsFromStore = () => dispatch => dispatch({ type: actionTypes.RESET_STATE });

export const getAnnotationsAction = (employeeCode) => async (dispatch) => {
  dispatch({ type: actionTypes.GET });
  try {
    let response = await getAnnotationsAPI(`?employeeCode=${employeeCode}`);

    let annotations = response.data && response.data.annotations;
    let categories = response.data && response.data.categorias;
    let types = response.data && response.data.tipos;
    let subtypes = response.data && response.data.subtipos;
    let status = response.data && response.data.status;
    let userData = response.data && response.data.user_data;

    if (status !== "OK") {
      dispatch({ type: actionTypes.GET_REJECTED });
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultException"), severity: "error" }));
      return;
    }

    dispatch({
      type: actionTypes.GET_FULFILLED,
      payload: {
        annotations,
        categories,
        types,
        subtypes,
        userData,
      },
    });
    return response;
  } catch (e) {
    dispatch({ type: actionTypes.GET_REJECTED, payload: e });

    dispatch(openSnackbarAction({ msg: getErrorStringFromError(e), severity: "error" }));
  }
};

export const deleteAnnotationAction = (code) => async (dispatch) => {
  dispatch({ type: actionTypes.DELETE });
  try {
    let response = await deleteAnnotationAPI({ Code: code });
    let status = response.data && response.data.status;

    if (status !== "OK") {
      dispatch({ type: actionTypes.DELETE_REJECTED });
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultException"), severity: "error" }));
      return;
    }

    dispatch({
      type: actionTypes.DELETE_FULFILLED,
      payload: { code },
    });
    return response;
  } catch (e) {
    dispatch({ type: actionTypes.DELETE_REJECTED });
  }
};

export const createAnnotationAction = (dataToSend) => async (dispatch) => {
  dispatch({ type: actionTypes.CREATE });
  try {
    let formData = new FormData();
    Object.keys(dataToSend).forEach((key) => {
      if (key === 'file' && !dataToSend[key]) {
        return;
      }

      formData.append(key, dataToSend[key])
    });

    let response = await createAnnotationAPI(formData);
    let status = response.data && response.data.status;

    if (status !== "OK") {
      dispatch({ type: actionTypes.CREATE_REJECTED });
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultException"), severity: "error" }));
      return;
    }

    dispatch({
      type: actionTypes.CREATE_FULFILLED,
      payload: response.data.data,
    });
    return response;
  } catch (e) {
    dispatch({ type: actionTypes.CREATE_REJECTED });
  }
};

export const updateAnnotationAction = (dataToSend) => async (dispatch) => {
  dispatch({ type: actionTypes.UPDATE });
  try {
    let formData = new FormData();
    Object.keys(dataToSend).forEach((key) => {
      if (key === 'file' && !dataToSend[key]) {
        return;
      }

      formData.append(key, dataToSend[key])
    });

    let response = await updateAnnotationAPI(formData);
    let status = response.data && response.data.status;

    if (status !== "OK") {
      dispatch({ type: actionTypes.UPDATE_REJECTED });
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultException"), severity: "error" }));
      return;
    }

    dispatch({
      type: actionTypes.UPDATE_FULFILLED,
      payload: response.data.data,
    });
    return response;
  } catch (e) {
    dispatch({ type: actionTypes.UPDATE_REJECTED });
  }
};