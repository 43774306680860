import React from "react";
import { CloseIcon } from "@icarius-icons";
import { Dialog, DialogTitle } from "@material-ui/core";
import NewAnnotationContent from "./newAnnotationContent";
import { getLocalizedString } from "@icarius-localization/strings";
import DialogTitleDivider from "@icarius-common/dialogTitleDivider";
import PaperDraggable from "@icarius-common/paperDraggable";
import DialogTransition from "@icarius-common/dialogTransition";

const NewAnnotationDialog = (props) => {

  const {
    open,
    data,
    type,
    handleClose,
    handleProcess,
  } = props;

  return (
    <Dialog
      open={open}
      TransitionComponent={DialogTransition}
      PaperComponent={PaperDraggable}
      maxWidth={"md"}
      fullWidth
    >
      <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
        {Boolean(data) ? getLocalizedString("iconEditAnnotation") : getLocalizedString("iconNewAnnotation")}
        <DialogTitleDivider />
      </DialogTitle>
      <CloseIcon className={"dialog-close-icon icon"} onClick={handleClose} />
      <NewAnnotationContent
        data={data}
        type={type}
        handleProcess={handleProcess}
        handleClose={handleClose}
      />
    </Dialog>
  );
}

export default NewAnnotationDialog;
