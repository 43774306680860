import React from "react";
import Loader from "@icarius-common/loader";
import { Grid, } from "@material-ui/core";
import { getLocalizedString } from "@icarius-localization/strings";
import { useSelector } from "react-redux";
import {
  getAnnotationRows,
  getSubtypes,
  getIsLoading,
} from "../../selectors";
import ListContainer from "./listContainer";
import Charts from "../charts";

const Content = ({ filterIsOff, initialYear }) => {

  const isLoading = useSelector(getIsLoading);
  const data = useSelector(getAnnotationRows);
  const subtypes = useSelector(getSubtypes);

  const hasData = data?.length > 0;

  const getSubtypeName = (tipo, subtipo) => {
    let subArray = subtypes[tipo].filter(subtype => subtype.FldValue === subtipo)[0];
    return subArray ? subArray["Descr"] : getLocalizedString("noSubtype");
  };

  return (
    <div style={{ padding: '0px 24px' }}>
      <Loader open={isLoading} />
      <Grid container>
        {
          hasData &&
          <Grid item xs={12} md={2}>
            <Charts
              annotationData={data}
              getAnnotationSubtypeName={getSubtypeName}
            />
          </Grid>
        }
        <Grid className="annotationsPadding" item xs={12} md={hasData ? 10 : 12}>
          <ListContainer
            data={data}
            initialYear={initialYear}
            filterIsOff={filterIsOff}
            getSubtypeName={getSubtypeName}
          />
        </Grid>
      </Grid>
    </div>
  );
}

export default Content;
