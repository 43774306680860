import {
    dateClassRule,
    dateFormatter,
    dateValueGetter,
    dateFilterParams,
    dateComparator,
} from "@icarius-table/date";

export default [
    {
        pages: ['annexesPage'],
        config: {
            headerName: 'Instancia laboral',
            field: "Instancia string",
            filter: "agSetColumnFilter",
            chartDataType: "series",
            checkboxSelection: function (params) {
                return params.columnApi.getRowGroupColumns().length === 0;
            },
            headerCheckboxSelection: function (params) {
                return params.columnApi.getRowGroupColumns().length === 0;
            },
            headerCheckboxSelectionFilteredOnly: true,
            enableRowGroup: true,
        }
    },
    {
        pages: ['annexesPage'],
        config:
        {
            headerName: 'Tipo de archivo',
            field: 'Tipo string',
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: ['annexesPage'],
        config:
        {
            headerName: 'Nombre del archivo',
            field: 'Nombre archivo',
            filter: "agTextColumnFilter",
        }
    },
    {
        pages: ['annexesPage'],
        config:
        {
            headerName: 'Texto descriptivo del archivo',
            field: 'Texto',
            filter: "agTextColumnFilter",
        }
    },
    {
        pages: ['annexesPage'],
        config: {
            headerName: 'Fecha de carga inicial',
            field: 'Fecha de registro',
            filter: "agTextColumnFilter",
            comparator: dateComparator,
            filterParams: dateFilterParams,
            cellClassRules: dateClassRule,
            valueFormatter: dateFormatter,
            valueGetter: dateValueGetter,
        }
    },
    {
        pages: ['annexesPage'],
        config: {
            headerName: 'Hora de carga inicial',
            field: 'Hora de registro',
            filter: "agTextColumnFilter",
            comparator: dateComparator,
            filterParams: dateFilterParams,
            cellClassRules: dateClassRule,
            valueFormatter: dateFormatter,
            valueGetter: dateValueGetter,
        }
    },
    {
        pages: ['annexesPage'],
        config: {
            headerName: 'Responsable de la carga',
            field: 'Nombre registro',
            filter: "agTextColumnFilter",
        }
    },
    {
        pages: ['annexesPage'],
        config: {
            headerName: 'Fecha de última actualización',
            field: 'Fecha de actualizacion',
            filter: "agTextColumnFilter",
            comparator: dateComparator,
            filterParams: dateFilterParams,
            cellClassRules: dateClassRule,
            valueFormatter: dateFormatter,
            valueGetter: dateValueGetter,
        }
    },
    {
        pages: ['annexesPage'],
        config: {
            headerName: 'Hora de última actualización',
            field: 'Hora actualizacion',
            filter: "agTextColumnFilter",
            comparator: dateComparator,
            filterParams: dateFilterParams,
            cellClassRules: dateClassRule,
            valueFormatter: dateFormatter,
            valueGetter: dateValueGetter,
        }
    },
    {
        pages: ['annexesPage'],
        config: {
            headerName: 'Fecha de vencimiento',
            field: 'Fecha de vencimiento string',
            filter: "agTextColumnFilter",
            comparator: dateComparator,
            filterParams: dateFilterParams,
            cellClassRules: dateClassRule,
            valueFormatter: dateFormatter,
            valueGetter: dateValueGetter,
        }
    },
    {
        pages: ['annexesPage'],
        config: {
            headerName: 'Situación',
            field: 'Situación',
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: ['annexesPage'],
        config: {
            headerName: 'Firmante',
            field: 'signer',
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: ['annexesPage'],
        config: {
            headerName: 'Visible para el colaborador',
            field: 'Visible',
            filter: "agSetColumnFilter",
        }
    },
]