import React from "react";
import { useDispatch } from "react-redux";
import { openSnackbarAction } from "@icarius-common/snackbar/actions";
import { CloseIcon } from "@icarius-icons";
import { Typography, Dialog, DialogTitle, DialogContent, Grid } from "@material-ui/core";
import { getLocalizedString } from "@icarius-localization/strings";
import BarChart from "./charts/BarChart";
import PaperDraggable from "@icarius-common/paperDraggable"; 
import DialogTransition from "@icarius-common/dialogTransition.js";

const GraphsDialog = (props) => {

  const { open, data, handleClose } = props;
  const dispatch = useDispatch();

  if(!data){
    handleClose();
    dispatch(openSnackbarAction({ msg: getLocalizedString("noDataToChart"), severity: "error", duration: 10000 }));
    return null;
  }

  return (
    <Dialog
      TransitionComponent={DialogTransition}
      PaperComponent={PaperDraggable}
      open={open}
      scroll={"paper"}
      fullWidth={true}
      maxWidth={"md"}>
      <DialogTitle
        style={{ padding: "14px 10px 1px", cursor: 'move' }}
        id="draggable-dialog-title"
        disableTypography>
        <Grid container alignItems="center">
          <Grid item xs={12} md={7} style={{ paddingLeft: 15 }}>
            <Grid container>
              <Grid item xs={12} md={10}>
                <Typography className="whiteText" variant="h6" gutterBottom>
                  {getLocalizedString("charts")}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </DialogTitle>
      <CloseIcon className={"dialog-close-icon icon"} onClick={handleClose} />
      <DialogContent>
        <div style={{ width: "100%" }} className="container relative flex flex-row justify-between items-center">
          <Grid container direction="row" style={{ margin: 10 }}>
            {
              data.map((dataItem, index) => {
                return (
                  <Grid key={index} item xs={12} sm={6}>
                    <BarChart data={dataItem} />
                  </Grid>
                );
              })
            }
          </Grid>
        </div>
      </DialogContent>
    </Dialog>
  );
}

export default GraphsDialog;
