import { useState, useMemo } from "react";
import { createDateFromDDMMYYYY } from "@icarius-utils/date";

const useAnnexYear = (data) => {

  const [year, setYear] = useState("");

  const yearsArray = useMemo(() => {
    const yearsArrayAux = [];

    data.forEach(annex => {
      const annexYear = createDateFromDDMMYYYY(annex["Fecha de registro"]).getFullYear();

      if (!Boolean(yearsArrayAux.find(year => year.key === annexYear))) {
        yearsArrayAux.push({ key: annexYear, value: annexYear });
      }
    })

    return yearsArrayAux;
  }, [data])

  return { year, setYear, yearsArray }
}

export default useAnnexYear;
