import * as actionTypes from "./actionTypes";

const initialState = {
  isLoading: false,
  userDates: {},
  annotations: [],
  categories: [],
  types: [],
  subtypes: {},
};

export default function reducer(state = initialState, action) {
  let newArray, modifiedItem, index;
  switch (action.type) {
    case actionTypes.RESET_STATE:
      return {
        ...state,
        isLoading: false,
        annotations: [],
        categories: [],
        types: [],
        subtypes: {},
        userDates: {},
      };

    case actionTypes.GET:
      return { ...state, isLoading: true };
    case actionTypes.GET_FULFILLED:
      return {
        ...state,
        isLoading: false,
        annotations: action.payload.annotations,
        categories: action.payload.categories,
        types: action.payload.types,
        subtypes: action.payload.subtypes,
        userDates: action.payload.userData,
      };
    case actionTypes.GET_REJECTED:
      return { ...state, isLoading: false };

    case actionTypes.DELETE:
      return { ...state, isLoading: true };
    case actionTypes.DELETE_FULFILLED:
      newArray = [...state.annotations].filter(field => field["Codigo"] !== action.payload.code);
      return { ...state, isLoading: false, annotations: newArray };
    case actionTypes.DELETE_REJECTED:
      return { ...state, isLoading: false };

    case actionTypes.UPDATE:
      return { ...state, isLoading: true };
    case actionTypes.UPDATE_FULFILLED:
      newArray = state.annotations.filter(item => item.Codigo === action.payload.Codigo);
      modifiedItem = newArray[0];
      index = state.annotations.indexOf(modifiedItem);

      if (index > -1) {
        state.annotations.splice(index, 1);
      }

      modifiedItem.Tipo = action.payload.Tipo;
      modifiedItem.Sub = action.payload.Sub;
      modifiedItem.Categoria = action.payload.Categoria;
      modifiedItem.Texto = action.payload.Texto;
      modifiedItem.Visnote = action.payload.Visnote;
      modifiedItem.file = action.payload.file;
      modifiedItem["Fecha de actualizacion"] = action.payload["Fecha de actualizacion"];
      modifiedItem["Hora actualizacion"] = action.payload["Hora actualizacion"];
      modifiedItem["Fecha de registro"] = action.payload["Fecha de registro"];
      modifiedItem["Hora de registro"] = action.payload["Hora de registro"];

      newArray = [...state.annotations];
      newArray.unshift(modifiedItem);
      return { ...state, isLoading: false, annotations: newArray };
    case actionTypes.UPDATE_REJECTED:
      return { ...state, isLoading: false };

    case actionTypes.CREATE:
      return { ...state, isLoading: true };
    case actionTypes.CREATE_FULFILLED:
      newArray = [...state.annotations];
      newArray.unshift(action.payload);
      return { ...state, isLoading: false, annotations: newArray };
    case actionTypes.CREATE_REJECTED:
      return { ...state, isLoading: false };

    default:
      return state;
  }
}
