import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAnnotationDataFromServer } from "../actions";
import { getRows, getDateFormat, getLoading, getSubtypes } from "../selectors";
import { CLEAR_STATE } from "../actionTypes";
import { getIsExportingGrid } from "src/app/selectors";
import { getLocalizedString } from "@icarius-localization/strings";
import CommonPage from "@icarius-common/commonPage";
import paths from "@icarius-localization/paths";
import GraphsDialog from "./graphsDialog";
import TypeRenderer from "@icarius-table/renderersAndFilters/typeRenderer";
import CategoryRenderer from "@icarius-table/renderersAndFilters/categoryRenderer";
import AnnotationsPage from "@icarius-pages/annotationsPage/components/index";
import { getAnnotationsAction } from "@icarius-pages/annotationsPage/actions";
import { getUserDates, getIsLoading as getAnnotationsAnnexesPageLoading } from "@icarius-pages/annotationsPage/selectors";
import CustomIconButton from "@icarius-common/abmComponents/customIconButton";
import { downloadFromURL } from "@icarius-utils/download";
import { RESOURCES_ENDPOINT } from "@icarius-connection/endpoints";
import { openSnackbarAction } from "@icarius-common/snackbar/actions";

const AnnotationsAnalysis = () => {

  const dispatch = useDispatch();

  const data = useSelector(getRows);
  const subtypesArray = useSelector(getSubtypes);
  const dateFormat = useSelector(getDateFormat);
  const isLoading = useSelector(getLoading);
  const isExportinGrid = useSelector(getIsExportingGrid);
  const userDates = useSelector(getUserDates);
  const gettingAnnotations = useSelector(getAnnotationsAnnexesPageLoading);

  const [graphDialogIsOpen, setGraphDialogIsOpen] = useState(false);
  const [dataForCharts, setDataForCharts] = useState(null);
  const [annotationsAnnexesEmployee, setAnnotationsAnnexesEmployee] = useState(null);

  useEffect(() => {
    dispatch(getAnnotationDataFromServer());

    return () => {
      dispatch({ type: CLEAR_STATE });
    }
  }, [dispatch])

  const handleOpenGraphs = (gridRef) => {
    setGraphDialogIsOpen(true);
    setDataForCharts(createDataForLoansChart(gridRef));
  };

  const handleDownloadEvidence = (gridRef) => {
    let selectedRows = gridRef.api.getSelectedRows();
    if (selectedRows.length !== 1) {
      dispatch(openSnackbarAction({ msg: "Debe seleccionar un único registro", severity: "warning" }));
      return;
    }

    const annotation = selectedRows[0];

    if (!annotation.filePath) {
      dispatch(openSnackbarAction({ msg: "La anotación seleccionada no posee evidencia", severity: "warning" }));
      return;
    }

    downloadFromURL(RESOURCES_ENDPOINT + annotation.filePath, annotation.fileName);
  };

  const createDataForLoansChart = (gridRef) => {
    let datosOK = true;
    let filteredRows = [];

    let typesData = {
      data: {},
      type: "type",
      title: getLocalizedString("type"),
    };

    let subtypesData = {
      data: {},
      type: "subtype",
      title: getLocalizedString("subtype"),
    };

    gridRef.api.forEachNodeAfterFilter((row) => {
      if (!(row && row.data)) {
        datosOK = false;
      } else {
        filteredRows.push(row.data);
      }
    });

    if (!datosOK || filteredRows.length === 0) return null;

    //armar conteo de types
    typesData.data["Mérito"] = filteredRows.filter(row => row.type === "Mérito").length;
    typesData.data["Demérito"] = filteredRows.filter(row => row.type === "Demérito").length;
    typesData.data["Otras"] = filteredRows.filter(row => row.type === "Otras").length;

    subtypesArray.forEach(subtype => { //armar conteo de subtypes
      subtypesData.data[subtype] = filteredRows.filter(row => row.subtype === subtype).length;
    })

    return [typesData, subtypesData];
  };

  const chartsItem = (gridRef) => {
    if (data.length === 0) return null;
    return (
      <CustomIconButton
        title={getLocalizedString("charts")}
        onClick={() => handleOpenGraphs(gridRef)}
        type={"graphics"}
      />
    )
  }

  const downloadItem = (gridRef) => (
    <CustomIconButton
      title={'Descargar evidencia'}
      onClick={() => handleDownloadEvidence(gridRef)}
      type={"download"}
    />
  )

  const handleCloseAnnotationsAnnexesPage = () => {
    dispatch(getAnnotationDataFromServer());
    setAnnotationsAnnexesEmployee(null);
  };

  const handleRowClick = (employeeRow) => {
    const code = employeeRow.data["code"];
    if (code) {
      const dataToSet = {
        "CODIGO DE EMPLEADO": employeeRow.data.code,
        "APELLIDO Y NOMBRE": employeeRow.data.name,
        "MANAGER": employeeRow.data.manager
      };
      dispatch(getAnnotationsAction(code))
        .then(() => setAnnotationsAnnexesEmployee(dataToSet));
    }
  };

  const showAnnotationsAnnexesPage = Boolean(annotationsAnnexesEmployee != null);

  return (
    <>
      {
        showAnnotationsAnnexesPage &&
        <AnnotationsPage
          employee={annotationsAnnexesEmployee}
          userDates={userDates}
          handleClose={handleCloseAnnotationsAnnexesPage}
        />
      }
      {
        !showAnnotationsAnnexesPage &&
        <CommonPage
          menuItems={[chartsItem, downloadItem]}
          rowData={data}
          title={getLocalizedString("annotationsAnalysisTitle")}
          gridTitle={getLocalizedString("annotationsAnalysisTitle")}
          dateFormat={dateFormat}
          columnDefPage={paths.annotationsAnalysis}
          isLoading={isExportinGrid || gettingAnnotations || isLoading}
          hasExpand
          handleRowClick={handleRowClick}
          hasHelp
          codeFieldName={'code'}
          frameworkComponents={{ TypeRenderer, CategoryRenderer }}
        >
          {
            graphDialogIsOpen &&
            <GraphsDialog
              open={graphDialogIsOpen && data.length > 0}
              data={dataForCharts}
              handleClose={() => setGraphDialogIsOpen(false)}
            />
          }
        </CommonPage>
      }
    </>
  );
}

export default AnnotationsAnalysis;