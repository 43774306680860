import * as actionTypes from "./actionTypes";

const initialState = {
  loading: false,
  userDates: {},
  types: [],
  annexes: [],
  instances: [],
  annexestypes: {},
  colabModifiableDocuments: [],
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.CLEAR_ANNEXES:
      return {
        ...state,
        loading: false,
        annexes: [],
        instances: [],
        annexestypes: {},
        userDates: {},
      };

    case actionTypes.UPDATE_ANNEX_TYPES:
      return { ...state, annexestypes: action.payload };

    case actionTypes.GET_ANNEXES_ROWS_FULFILLED:
    case actionTypes.CREATE_ANNEX_FULFILLED:
    case actionTypes.DELETE_ANNEX_FULFILLED:
    case actionTypes.UPDATE_ANNEX_FULFILLED:
      return {
        ...state,
        loading: false,
        annexes: action.payload.annexes,
        instances: action.payload.instances,
        annexestypes: action.payload.types,
        userDates: action.payload.userData,
        colabModifiableDocuments: action.payload.colabModifiableDocuments,
      };

    case actionTypes.GET_ANNEXES_ROWS:
    case actionTypes.CREATE_ANNEX:
    case actionTypes.DELETE_ANNEX:
    case actionTypes.UPDATE_ANNEX:
    case actionTypes.DOWNLOAD_ANNEX:
    case actionTypes.DOWNLOAD_ANNEX_FOLDER:
      return { ...state, loading: true };

    case actionTypes.CREATE_ANNEX_REJECTED:
    case actionTypes.GET_ANNEXES_ROWS_REJECTED:
    case actionTypes.DELETE_ANNEX_REJECTED:
    case actionTypes.UPDATE_ANNEX_REJECTED:
    case actionTypes.DOWNLOAD_ANNEX_REJECTED:
    case actionTypes.DOWNLOAD_ANNEX_FULFILLED:
    case actionTypes.DOWNLOAD_ANNEX_FOLDER_REJECTED:
    case actionTypes.DOWNLOAD_ANNEX_FOLDER_FULFILLED:
      return { ...state, loading: false };

    default:
      return state;
  }
}
