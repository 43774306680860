import * as actionTypes from "./actionTypes";
import { openSnackbarAction } from "@icarius-common/snackbar/actions";
import { getErrorStringFromError } from "@icarius-localization/errors";
import { downloadBlob } from "@icarius-utils/download";
import {
  getAnnexesApi,
  createAnnexApi,
  deleteAnnexApi,
  updateAnnexApi,
  downloadAnnexApi,
  downloadAnnexFolderAPi,
} from "@icarius-connection/api";
import { getFileExtension, getFileName } from "@icarius-utils/fileUpload";
import { getLocalizedString, getLocalizedErrorString } from "@icarius-localization/strings";

export const clearAnnexesFromStore = () => dispatch => dispatch({ type: actionTypes.CLEAR_ANNEXES });

export const getAnnexesRowsFromServer = (employeeCode) => async (dispatch) => {
  dispatch({ type: actionTypes.GET_ANNEXES_ROWS });
  try {

    let query = employeeCode ? `?employeeCode=${employeeCode}` : "";

    let response = await getAnnexesApi(query);
    let annexes = response.data && response.data.annexes;
    let instances = response.data && response.data.instances;
    let types = response.data && response.data.types
    let status = response.data && response.data.status;
    let userData = response.data && response.data.user_data;
    let colabModifiableDocuments = response.data && response.data.can_colab_update;

    if (status !== "OK") {
      dispatch({ type: actionTypes.GET_ANNEXES_ROWS_REJECTED });
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultException"), severity: "error" }));
      return;
    }

    dispatch({
      type: actionTypes.GET_ANNEXES_ROWS_FULFILLED,
      payload: { annexes, instances, types, userData, colabModifiableDocuments },
    });
    return response;
  } catch (e) {
    dispatch({ type: actionTypes.GET_ANNEXES_ROWS_REJECTED, payload: e });
    dispatch(openSnackbarAction({ msg: getErrorStringFromError(e), severity: "error" }));
  }
};

export const createAnnexInServer = (annex) => async (dispatch) => {
  dispatch({ type: actionTypes.CREATE_ANNEX });
  try {
    let response = await createAnnexApi(annex);
    let status = response.data && response.data.status;
    let annexes = response.data && response.data.annexes;
    let instances = response.data && response.data.instances;
    let types = response.data && response.data.types
    let userData = response.data && response.data.user_data;
    let colabModifiableDocuments = response.data && response.data.can_colab_update;

    if (status !== "OK") {
      dispatch({ type: actionTypes.CREATE_ANNEX_REJECTED });
      if (status === "ERROR_NAME_DUPLICATED") {
        dispatch(openSnackbarAction({ msg: getLocalizedString("fileSameName"), severity: "error" }));
        return;
      } else if (status === "ERROR_FILE_EXTENSION") {
        dispatch(openSnackbarAction({ msg: getLocalizedString("fileTypeNotValid"), severity: "error" }));
        return;
      } else {
        dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultException"), severity: "error" }));
        return;
      }
    }

    dispatch({
      type: actionTypes.CREATE_ANNEX_FULFILLED,
      payload: { annexes, instances, types, userData, colabModifiableDocuments },
    });

    return response;
  } catch (e) {
    dispatch({ type: actionTypes.CREATE_ANNEX_REJECTED });
    dispatch(openSnackbarAction({ msg: getErrorStringFromError(e), severity: "error" }));
  }
};

export const deleteAnnexFromServer = (code) => async (dispatch) => {
  dispatch({ type: actionTypes.DELETE_ANNEX });
  try {
    let response = await deleteAnnexApi({ Code: code });
    let status = response.data && response.data.status;
    let annexes = response.data && response.data.annexes;
    let instances = response.data && response.data.instances;
    let types = response.data && response.data.types
    let userData = response.data && response.data.user_data;
    let colabModifiableDocuments = response.data && response.data.can_colab_update;

    if (status !== "OK") {
      dispatch({ type: actionTypes.DELETE_ANNEX_REJECTED });
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultException"), severity: "error" }));
      return;
    }

    dispatch({
      type: actionTypes.DELETE_ANNEX_FULFILLED,
      payload: { annexes, instances, types, userData, colabModifiableDocuments },
    });

    return response;
  } catch (e) {
    dispatch({ type: actionTypes.DELETE_ANNEX_REJECTED });
    dispatch(openSnackbarAction({ msg: getErrorStringFromError(e), severity: "error" }));
  }
};

export const updateAnnexInServer = (annex) => async (dispatch) => {
  dispatch({ type: actionTypes.UPDATE_ANNEX });
  try {
    let response = await updateAnnexApi(annex);
    let status = response.data && response.data.status;
    let annexes = response.data && response.data.annexes;
    let instances = response.data && response.data.instances;
    let types = response.data && response.data.types
    let userData = response.data && response.data.user_data;
    let colabModifiableDocuments = response.data && response.data.can_colab_update;

    if (status !== "OK") {
      dispatch({ type: actionTypes.UPDATE_ANNEX_REJECTED });
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultException"), severity: "error" }));
      return;
    }

    dispatch({
      type: actionTypes.UPDATE_ANNEX_FULFILLED,
      payload: { annexes, instances, types, userData, colabModifiableDocuments },
    });

    return response;
  } catch (e) {
    dispatch({ type: actionTypes.UPDATE_ANNEX_REJECTED });
    dispatch(openSnackbarAction({ msg: getErrorStringFromError(e), severity: "error" }));
  }
};

export const downloadAnnexInServer = (code) => async (dispatch) => {
  dispatch({ type: actionTypes.DOWNLOAD_ANNEX });
  try {
    let response = await downloadAnnexApi({ Code: code });
    let documents = response.data && response.data.documents;
    let title = response.headers["x-content-namefile"];

    downloadBlob(response.data, getFileExtension(title), getFileName(title));

    if (response.status !== 200) {
      dispatch({ type: actionTypes.DOWNLOAD_ANNEX_REJECTED });
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultException"), severity: "error" }));
      return;
    }

    dispatch({
      type: actionTypes.DOWNLOAD_ANNEX_FULFILLED,
      payload: { documents }
    });
    return response;
  } catch (e) {
    dispatch({ type: actionTypes.DOWNLOAD_ANNEX_REJECTED, payload: e });
    dispatch(openSnackbarAction({ msg: getErrorStringFromError(e), severity: "error" }));
  }
};

export const downloadAnnexFolderInServer = (dataToSend) => async (dispatch) => {
  dispatch({ type: actionTypes.DOWNLOAD_ANNEX_FOLDER });
  try {
    let response = await downloadAnnexFolderAPi(dataToSend);
    let documents = response.data && response.data.documents;
    let title = response.headers["x-content-namefile"];

    downloadBlob(response.data, getFileExtension(title), getFileName(title));

    if (response.status !== 200) {
      dispatch({ type: actionTypes.DOWNLOAD_ANNEX_FOLDER_REJECTED });
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultException"), severity: "error" }));
      return;
    }

    dispatch({
      type: actionTypes.DOWNLOAD_ANNEX_FOLDER_FULFILLED,
      payload: { documents }
    });
    return response;
  } catch (e) {
    dispatch({ type: actionTypes.DOWNLOAD_ANNEX_FOLDER_REJECTED, payload: e });
    dispatch(openSnackbarAction({ msg: getErrorStringFromError(e), severity: "error" }));
  }
};