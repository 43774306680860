import React from "react";
import { ListItem, IconButton, Grid, Typography } from "@material-ui/core";
import _ from "@lodash";
import AnnotationText from "./annotationText";
import { withStyles } from "@material-ui/core/styles";
import { getLocalizedString } from "@icarius-localization/strings";
import { getCategoryColor, getAnnotationTypeColor } from "@icarius-utils/colors";
import { ErrorIcon, EditIcon, DeleteIcon } from "@icarius-icons";
import { formatDate, getLocalTimeFromUTCStringDateAndStringTime } from "@icarius-utils/date";
import { useSelector } from "react-redux";
import { getTheme } from "@icarius-pages/login/selectors";
import { GetAppIcon } from "@icarius-icons/index";
import { downloadFromURL } from "@icarius-utils/download";
import { RESOURCES_ENDPOINT } from "@icarius-connection/endpoints";

const AnnotationsListItem = (props) => {

  const {
    color,
    item,
    isMyAnnotation,
    getSubtypeName,
    handleOpenDeleteDialog,
    handleOpenEditDialog,
    handleViewAnnotation
  } = props;

  const { theme } = useSelector(getTheme);

  const getTipo = () => {
    switch (item["Tipo"]) {
      case "ME":
        return getLocalizedString("merit");
      case "DE":
        return getLocalizedString("demerit");
      default:
        return getLocalizedString("noType");
    }
  };

  const CustomListItem = withStyles({
    root: {
      paddingLeft: 0,
    },
  })(ListItem);

  const CustomTypography = withStyles({
    root: {
      color: getAnnotationTypeColor(item["Tipo"], theme),
      fontWeight: "bold",
    },
  })(Typography);

  const localDate = getLocalTimeFromUTCStringDateAndStringTime(item["Fecha de registro"], item['Hora de registro']);

  return (
    <CustomListItem className="listItemBorderBottom whiteText" dense button>
      <Grid container>
        <Grid item xs={11}>
          <div onClick={() => handleViewAnnotation(item)} className="flex flex-1 flex-col relative overflow-hidden">
            <Grid container>
              <Grid item xs={1} style={{ display: "flex", alignItems: "center", justifyContent: "center", paddingLeft: 10 }}>
                <ErrorIcon htmlColor={getCategoryColor(item["Categoria"], theme, true)} />
              </Grid>
              <Grid container item xs={11} style={{ paddingLeft: 10 }}>
                <Grid item xs={12} sm={6} md={4}>
                  <AnnotationText text={`${formatDate(localDate)} ${localDate.toTimeString().split(' ')[0].substring(0, 5)} hrs`} />
                </Grid>
                <Grid item xs={12} sm={6} md={8}>
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <CustomTypography
                      align="justify"
                      variant="subtitle1"
                      className="todo-title truncate">
                      {getTipo()}
                    </CustomTypography>
                    <AnnotationText shouldtruncate text={getLocalizedString("by") + item["Nombre registro"]} />
                  </div>
                </Grid>
                <Grid item xs={12} sm={6} md={8}>
                  <AnnotationText isBold text={getSubtypeName(item["Tipo"], item["Sub"])} />
                </Grid>
                <Grid item xs={12}>
                  <AnnotationText shouldtruncate text={_.truncate(item["Texto"].replace(/<(?:.|\n)*?>/gm, ""), { length: 180 })} />
                </Grid>
                {
                  Boolean(item.file) &&
                  <Grid item xs={12}>
                    <AnnotationText shouldtruncate text={'Evidencia: ' + _.truncate(item.file.name.replace(/<(?:.|\n)*?>/gm, ""), { length: 180 })} />
                  </Grid>
                }
              </Grid>
            </Grid>
          </div>
        </Grid>
        <Grid container item xs={1} direction="row" justify="center">
          {
            !isMyAnnotation &&
            <>
              {
                item["editar"] === "Y" &&
                <Grid container item justify="center" xs={6}>
                  <IconButton onClick={() => handleOpenEditDialog(item)}>
                    <EditIcon htmlColor={color} />
                  </IconButton>
                </Grid>
              }
              {
                item["editar"] === "Y" &&
                <Grid container item justify="center" xs={6}>
                  <IconButton onClick={() => handleOpenDeleteDialog(item)}>
                    <DeleteIcon htmlColor={color} />
                  </IconButton>
                </Grid>
              }
            </>
          }
          {
            Boolean(item.file) &&
            <Grid container item justify="center" xs={6}>
              <IconButton onClick={() => downloadFromURL(RESOURCES_ENDPOINT + item.file.path, item.file.name)}>
                <GetAppIcon htmlColor={color} />
              </IconButton>
            </Grid>
          }
        </Grid>
      </Grid>
    </CustomListItem>
  );
}

export default AnnotationsListItem;
