import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { Card, CardContent, Grid } from "@material-ui/core";
import { useSelector } from "react-redux";
import { getTheme } from "@icarius-pages/login/selectors";
import DoughnutChart from './doughnutChart';
import getAnnotationChartData from './getAnnotationChartData';

const Charts = (props) => {

  const {
    annotationData,
    getAnnotationSubtypeName,
  } = props;

  const { theme } = useSelector(getTheme);

  const ModifiedCard = withStyles({
    root: {
      height: "22vh",
      marginBottom: "1vh",
      maxWidth: "100%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      backgroundColor: theme === "light" && "var(--secondaryBackgroundColor)",
    },
  })(Card);

  const ModifiedCardContent = withStyles({
    root: {
      width: "100%",
      padding: 4,
      "&:last-child": {
        paddingBottom: 4,
      },
    },
  })(CardContent);

  const chartData = getAnnotationChartData(annotationData, getAnnotationSubtypeName);

  return (
    <div
      style={{ width: "100%", marginTop: 10 }}
      className="container relative flex flex-row justify-between items-center">
      <Grid container direction="column">
        {
          chartData.map((dataItem, index) => (
            <Grid key={index} item xs={12}>
              <ModifiedCard>
                <ModifiedCardContent>
                  <DoughnutChart data={dataItem} />
                </ModifiedCardContent>
              </ModifiedCard>
            </Grid>
          ))
        }
      </Grid>
    </div>
  );
}

export default Charts;