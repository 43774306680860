import React, { useState } from "react";
import { getLocalizedString } from "@icarius-localization/strings";
import {
  Grid,
  FormControlLabel,
  Switch,
} from "@material-ui/core";
import CommonPage from "@icarius-common/commonPage";
import StyledHelpMenu from "@icarius-common/styledMenu";
import MenuItemWithIcon from "@icarius-common/MenuItemWithIcon";
import CustomIconButton from "@icarius-common/abmComponents/customIconButton";
import Header from './header';
import Content from "./annotations/content";

export const EmployeeContext = React.createContext();

const AnnotationsPage = (props) => {

  const {
    initialYear,
    employee,
    userDates,
    handleClose,
  } = props;

  const [anchorEl, setAnchorEl] = useState(null);
  const [filterIsOff, setFilterIsOff] = useState(true);

  const handleClickHelpMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseHelpMenu = () => {
    setAnchorEl(null);
  };

  return (
    <EmployeeContext.Provider value={employee}>
      <CommonPage
        isNotGridPage
      >
        <Header
          employee={employee}
          userDates={userDates}
        />
        <Grid
          container item xs={12} justify="space-between"
          style={{ padding: "0px 30px 0px 25px" }}
        >
          <Grid item>
            <FormControlLabel
              control={
                <Switch
                  checked={filterIsOff}
                  value={filterIsOff}
                  onChange={() => setFilterIsOff(prev => !prev)}
                />
              }
              label={getLocalizedString(filterIsOff ? "allAnnotations" : "annotationsByDate")}
              labelPlacement="start"
              style={{ margin: 0 }}
            />
          </Grid>
          <Grid item>
            <CustomIconButton
              title={getLocalizedString("goBack")}
              onClick={handleClose}
              type={"goBack"}
            />
            <>
              <CustomIconButton
                title={getLocalizedString("help")}
                onClick={handleClickHelpMenu}
                type={"help"}
              />
              <StyledHelpMenu
                anchorEl={anchorEl}
                isOpen={Boolean(anchorEl)}
                onClose={handleCloseHelpMenu}>
                <MenuItemWithIcon
                  value={"https://www.youtube.com/embed/6rz1v3ntEwA"}
                  text={getLocalizedString("annotations")}
                  type={"video"}
                />
              </StyledHelpMenu>
            </>
          </Grid>
        </Grid>
        <Content
          filterIsOff={filterIsOff}
          initialYear={initialYear}
        />
      </CommonPage>
    </EmployeeContext.Provider>
  );
}

export default AnnotationsPage;
