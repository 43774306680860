import { useState } from "react";
import {
  convertDateToUTC,
  convertTimeStringToDate,
  convertUTCDateToLocalDate,
  createDateFromDDMMYYYY,
  formatDateYYYYMMDD,
} from "@icarius-utils/date";

const useHandleForm = (data, type, employee, callback, invalidDataCallback) => {

  const isCreate = !Boolean(data);

  const createInitialData = () => {

    const getInitialFieldData = (fieldName) => {
      if (fieldName === 'descripcion') {
        return isCreate ? '' : data['Texto'];
      }

      if (fieldName === 'Tipo') {
        return isCreate ? (type || "") : data['Tipo'];
      }

      if (fieldName === 'Sub') {
        return isCreate ? "" : data['Sub'];
      }

      if (fieldName === 'Categoria') {
        return isCreate ? "" : data['Categoria'] || "-";
      }

      if (fieldName === 'switchVisibility') {
        return isCreate ? true : data['Visnote'] === "Y";
      }

      if (fieldName === 'fechaRegistro') {
        return isCreate ? new Date() : convertUTCDateToLocalDate(createDateFromDDMMYYYY(data['Fecha de actualizacion']));
      }

      if (fieldName === 'horaRegistro') {
        return isCreate ? new Date() : convertUTCDateToLocalDate(convertTimeStringToDate(data['Hora actualizacion']));
      }

      if (fieldName === 'file') {
        return isCreate ? null : data[fieldName] ? [data[fieldName]] : null; // tiene que estar en array para el input
      }

      return isCreate ? "" : data[fieldName];
    }

    const fieldNames = [
      'descripcion',
      'Tipo',
      'Sub',
      'Categoria',
      'switchVisibility',
      'fechaRegistro',
      'horaRegistro',
      'file',
    ];

    return fieldNames.reduce((initialData, fieldName) => {
      return ({
        ...initialData,
        [fieldName]: getInitialFieldData(fieldName)
      })
    }, {});
  }

  const [formData, setFormData] = useState(createInitialData());

  // verificar que la fecha y hora recibida, sea menor que la fecha y hora de ahora
  const dateTimeAreValid = () => {
    const fullDate = new Date(
      formData.fechaRegistro.getFullYear(), formData.fechaRegistro.getMonth(), formData.fechaRegistro.getDate(),
      formData.horaRegistro.getHours(), formData.horaRegistro.getMinutes()
    )

    if (fullDate > new Date()) return false;
    return true;
  };

  const dataIsComplete = () => {
    if (formData.Tipo && formData.Categoria && formData.descripcion && formData.fechaRegistro && formData.horaRegistro) {
      if (formData.Tipo === 'OT') return true;
      if (formData.Sub) return true;
      return false;
    }

    return false;
  };

  const submit = async () => {
    if (dataIsComplete() && dateTimeAreValid()) {

      // para que al pasar a UTC no se pase de dia
      const auxRegisterDate = formData.fechaRegistro;
      auxRegisterDate.setHours(0);
      auxRegisterDate.setMinutes(0);
      auxRegisterDate.setSeconds(0);
      auxRegisterDate.setMilliseconds(0);

      const currentFormFile = formData.file?.length ? formData.file[0] : null;

      const dataToSend = {
        Code: Boolean(data) ? data.Codigo : null,
        Codigo: employee["CODIGO DE EMPLEADO"],
        Tipo: formData.Tipo,
        Sub: formData.Sub,
        Categoria: formData.Categoria,
        Texto: formData.descripcion,
        file: currentFormFile,
        Visnote: formData.switchVisibility === false ? "N" : "Y",
        FechaRegistro: formatDateYYYYMMDD(convertDateToUTC(auxRegisterDate)),
        HoraRegistro: convertDateToUTC(formData.horaRegistro).toTimeString().split(' ')[0].substring(0, 5),
      };

      if (!isCreate) {
        dataToSend.file = data.file === currentFormFile ? null : currentFormFile;
        dataToSend.keepFile = Boolean(data.file && data.file === currentFormFile);
      }

      if (Boolean(data)) {
        delete dataToSend.Codigo;
      } else {
        delete dataToSend.Code;
      }

      callback(dataToSend);
      return true;
    } else {
      invalidDataCallback();
      return false;
    }
  }

  const setFormValue = (value, fieldName) => {
    if (fieldName === 'Tipo') {
      setFormData({
        ...formData,
        [fieldName]: value,
        Sub: '',
      })
      return;
    }

    setFormData({
      ...formData,
      [fieldName]: value,
    })
  }

  return { isCreate, formData, setFormValue, submit };
}

export default useHandleForm;