import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllergiesExamsDiseasesAction } from "../actions";
import { getLoading, getData } from "../selectors";
import { getAppColor } from "src/app/selectors";
import { Grid } from "@material-ui/core";
import DataItem from "./data/dataItem";
import { RESET_STATE } from "../actionTypes";
import ABMLayout from "@icarius-common/abmComponents/abmLayout";
import paths from "@icarius-localization/paths";
import { useLocation } from "react-router-dom";

export const TypeContext = React.createContext("");

const AllergiesExamsDiseases = ({ history }) => {

  const [createIsOpen, setCreateIsOpen] = useState(false);

  const location = useLocation();
  const dispatch = useDispatch();

  const getTypeString = () => {
    return location.pathname === paths.allergiesSettings ? "alergia" : location.pathname === paths.examsSettings ? "exámen médico" : "enfermedad";
  }

  const type = location.pathname === paths.allergiesSettings ? "A" : location.pathname === paths.examsSettings ? "E" : "D";

  const color = useSelector(getAppColor);
  const data = useSelector(getData);
  const isLoading = useSelector(getLoading);

  useEffect(() => {
    dispatch(getAllergiesExamsDiseasesAction(type));
    return () => { dispatch({ type: RESET_STATE }) }
  }, [dispatch, type])

  const getDataIsEmpty = () => {
    return !data || data.length === 0;
  }

  return (
    <TypeContext.Provider value={{ type, getTypeString }}>
      <ABMLayout
        title={type === "E" ? "Definición de exámenes médicos" : `Definición de tipo de ${getTypeString()}`}
        isLoading={isLoading}
        showCreateButton={!createIsOpen}
        createButtonTitle={`Nuevo tipo de ${getTypeString()}`}
        createButtonOnClick={() => setCreateIsOpen(true)}
        showGoBack
        handleGoBack={() => history.push(paths.allergiesExamsDiseases)}
        showNoData={getDataIsEmpty()}
        noDataTitle={`No se encontraron tipos de ${getTypeString()}`}
        noDataCreateTitle={`Nuevo tipo de ${getTypeString()}`}
        noDataOnClick={() => setCreateIsOpen(true)}
      >
        <Grid container item xs={12} direction="row">
          {
            createIsOpen &&
            <Grid container item xs={12} style={{ margin: "10px 0px" }}>
              <DataItem
                color={color}
                data={null}
                closeNew={() => setCreateIsOpen(false)}
              />
            </Grid>
          }
          {
            data && data.map((item) => {
              return (
                <Grid container item xs={12} style={{ margin: "10px 0px" }} key={item.code}>
                  <DataItem data={item} color={color} />
                </Grid>
              )
            })
          }
        </Grid>
      </ABMLayout>
    </TypeContext.Provider>
  )
}

export default AllergiesExamsDiseases;