import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import { getAnnotationTypes } from "@icarius-pages/annotationsPage/selectors";
import { getMyAnnotationTypes } from "@icarius-pages/myAnnotations/selectors";
import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component';
import { getAnnotationTypeColor, getCategoryColor } from "@icarius-utils/colors";
import { getAppColor } from "src/app/selectors";
import { createDateFromDDMMYYYY, formatDate } from "@icarius-utils/date";
import { getTheme } from "@icarius-pages/login/selectors";
import 'react-vertical-timeline-component/style.min.css';

const Timeline = ({ data, getSubtypeName }) => {

  const color = useSelector(getAppColor);
  const types = useSelector(getAnnotationTypes);
  const myTypes = useSelector(getMyAnnotationTypes);
  const theme = useSelector(getTheme);

  const getTypeName = (tipo) => {
    const typesToUse = Boolean(types?.length > 0) ? types : myTypes;
    return typesToUse.filter(type => type.FldValue === tipo)[0]["Descr"];
  };

  const orderedData = useMemo(() => {
    return data.sort((a, b) => {
      const aDate = createDateFromDDMMYYYY(a['Fecha de actualizacion']);
      const bDate = createDateFromDDMMYYYY(b['Fecha de actualizacion']);

      if (aDate < bDate) return -1;
      if (aDate > bDate) return 1;
      return 0;
    })
  }, [data])

  const monthNames = ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"];

  return (
    <VerticalTimeline lineColor={color}>
      {
        orderedData.map(item => {

          const date = createDateFromDDMMYYYY(item['Fecha de actualizacion']);
          const month = date.getMonth();

          return (
            <VerticalTimelineElement
              key={item['Codigo']}
              className="vertical-timeline-element--work"
              contentStyle={{ background: 'var(--modalBg)', color: 'var(--mainText)', boxShadow: 'none' }}
              contentArrowStyle={{ borderRight: '7px solid  var(--drawer)' }}
              date={`${monthNames[month]} - ${formatDate(date)}`}
              iconStyle={{ background: color }}
            >
              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <h3 className="vertical-timeline-element-title" style={{ color: getAnnotationTypeColor(item['Tipo'], theme.theme) }}>
                  {getTypeName(item['Tipo'])}
                </h3>
                <div
                  style={{
                    borderRadius: '50%',
                    backgroundColor: getCategoryColor(item["Categoria"], theme.theme, true),
                    width: 20,
                    height: 20,
                    display: 'flex',
                    justifyContent: 'center'
                  }}
                >
                  !
                </div>
              </div>
              <h3 className="vertical-timeline-element-title">{getSubtypeName(item['Tipo'], item['Sub'])}</h3>
              <p style={{ margin: 0, fontWeight: 300 }}>
                {item['Texto']}
              </p>
              <p style={{ margin: 0, fontWeight: 500, fontStyle: 'italic' }}>
                {item['Nombre actualizacion']}
              </p>
              {
                Boolean(item.file) &&
                <p style={{ margin: 0, marginTop: 10, fontWeight: 300 }}>
                  {`Evidencia: ${item.file.name}`}
                </p>
              }
            </VerticalTimelineElement>
          )
        })
      }
    </VerticalTimeline>
  );
}

export default Timeline;
