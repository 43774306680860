import * as actionTypes from "./actionTypes";

const initialState = {
  rows: [],
  locale: "es",
  dateFormat: "yyyy/mm/dd",
  isLoading: false,
  types: null,
  subtypes: null,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.CLEAR_STATE:
      return initialState;
    case actionTypes.GET_ROWS:
      return { ...state, isLoading: true };
    case actionTypes.GET_ROWS_FULFILLED:
      return {
        ...state,
        isLoading: false,
        rows: action.payload.rows,
        dateFormat: action.payload.dateFormat,
        types: action.payload.types,
        subtypes: action.payload.subtypes,
      };
    case actionTypes.GET_ROWS_REJECTED:
      return { ...state, isLoading: false };

    default:
      return state;
  }
}
