import React from "react";
import { LinkedinIcon } from "@icarius-icons";
import { getLocalizedString } from "@icarius-localization/strings";
import { Grid, Typography, Link } from "@material-ui/core";
import { useSelector } from "react-redux";
import { getAppColor } from "src/app/selectors";

const Header = ({ employee, userDates }) => {

  const color = useSelector(getAppColor);

  const managerName = (employee && employee["MANAGER"] && employee["MANAGER"] !== "-") ? employee["MANAGER"] : "-";
  const hasLinkedin = employee && employee["LINKEDIN"] && employee["LINKEDIN"] !== "-";
  const name = Boolean(employee && employee["APELLIDO Y NOMBRE"]) ? `${getLocalizedString("annotations")} de ${employee["APELLIDO Y NOMBRE"]}` : '';

  return (
    <Grid container direction="row" style={{ padding: '25px 30px 15px 25px' }}>
      <Grid container direction="row" item xs={11}>
        <div style={{ display: 'flex', flexWrap: 'wrap', width: '100%', justifyContent: 'space-between' }}>
          <Typography className="whiteText" variant="h6" style={{ fontWeight: 600, fontSize: '1.1rem', paddingRight: 20 }}>
            {name}
          </Typography>
          <div style={{ display: 'flex', flexWrap: 'wrap' }}>
            <Typography className="whiteText" variant="h6" style={{ fontWeight: 100, marginRight: 5, fontSize: '1.1rem' }}>
              Manager:
            </Typography>
            <Typography style={{ color: color, fontWeight: 600, fontSize: '1.1rem', paddingRight: 20 }} variant="h6">
              {managerName}
            </Typography>
          </div>
          <div style={{ display: 'flex', flexWrap: 'wrap' }}>
            <Typography className="whiteText" variant="h6" style={{ fontWeight: 100, marginRight: 5, fontSize: '1.1rem' }}>
              {getLocalizedString("incomeDate")}:
            </Typography>
            <Typography style={{ color: color, fontWeight: 600, fontSize: '1.1rem', paddingRight: 20 }} variant="h6">
              {userDates["Fecha contratacion"]}
            </Typography>
          </div>
          <div style={{ display: 'flex', flexWrap: 'wrap' }}>
            <Typography className="whiteText" variant="h6" style={{ fontWeight: 100, marginRight: 5, fontSize: '1.1rem' }}>
              {getLocalizedString("contractEndDate")}:
            </Typography>
            <Typography style={{ color: color, fontWeight: 600, fontSize: '1.1rem' }} variant="h6">
              {userDates["Fecha termino contrato"] || "-"}
            </Typography>
          </div>
        </div>
        {
          hasLinkedin &&
          <Grid container item xs={12}>
            <Link
              rel="noopener"
              target="_blank"
              variant="subtitle1"
              className="whiteText"
              style={{ paddingTop: 5, fontWeight: "bold" }}
              href={`https://www.linkedin.com/in/${employee["LINKEDIN"]}`}>
              <Grid container direction="row">
                <div style={{ marginTop: 2, marginRight: 5, width: "20px", fill: "#0073b2" }}>
                  <LinkedinIcon className={"icon"} />
                </div>
                <Typography style={{ paddingRight: 10 }} className="whiteText" variant="subtitle1">
                  {`linkedin.com/in/${employee["LINKEDIN"]}`}
                </Typography>
              </Grid>
            </Link>
          </Grid>
        }
      </Grid>
    </Grid>
  );
}

export default Header;
