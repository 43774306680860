import React, { useState, useEffect } from "react";
import { getLocalizedString } from "@icarius-localization/strings";
import CommonPage from "@icarius-common/commonPage";
import {
  FormControlLabel,
  Switch,
  List,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
} from "@material-ui/core";
import CustomIconButton from "@icarius-common/abmComponents/customIconButton";
import InstanceListDialog from "./dialogs/instanceListDialog";
import TypesDialog from "./dialogs/typesDialog";
import CreateEditDialog from "./dialogs/createEditDialog";
import Timeline from './timeline';
import useAnnexes from "./useAnnexes";
import StyledHelpMenu from "@icarius-common/styledMenu";
import MenuItemWithIcon from "@icarius-common/MenuItemWithIcon";
import { AccountTreeIcon } from "@icarius-icons/index";

const useForceFirstRender = () => {
  const [count, setCount] = useState(0)
  useEffect(() => {
    if (!count) setCount(1);
  }, [count])
}

const AnnexesPage = (props) => {

  useForceFirstRender();

  const { employeeName, employeeCode, handleClose } = props;
  const {
    state,
    handlers,
    arrayToRender,
    isLoading,
    types,
    instances,
    userDates,
    year,
    setYear,
    yearsArray,
    setAnnexesTypeToShow,
    getAnnexTypeName,
    getAnnexInstanceName,
  } = useAnnexes(employeeCode);

  const showTimeline = year !== '';
  const title = `${getLocalizedString("annex")}${Boolean(employeeName) ? ` de ${employeeName}` : ''}`;

  const goBackItem = () =>
    <CustomIconButton
      title={getLocalizedString("goBack")}
      onClick={handleClose}
      type={"goBack"}
    />

  const downloadItem = (gridRef) =>
    <>
      <CustomIconButton
        type={'download'}
        title={'Descargar'}
        onClick={(e) => handlers.handleOpenDownloadMenu(e)}
      />
      <StyledHelpMenu
        anchorEl={state.downloadMenuIsOpen}
        isOpen={Boolean(state.downloadMenuIsOpen)}
        onClose={handlers.handleCloseDownloadMenu}
      >
        <MenuItemWithIcon
          text={'Carpeta completa'}
          onClick={() => handlers.handleOpenDownloadDialog(null, { Codigo: employeeCode, ignoreHidden: false })}
        />
        <MenuItemWithIcon
          text={'Carpeta sin archivos ocultos'}
          onClick={() => handlers.handleOpenDownloadDialog(null, { Codigo: employeeCode, ignoreHidden: true })}
        />
        <MenuItemWithIcon
          text={'Archivos seleccionados'}
          onClick={() => handlers.handleOpenDownloadDialog(gridRef, { Codigo: employeeCode })}
        />
      </StyledHelpMenu>
    </>

  const previewItem = (gridRef) =>
    <CustomIconButton
      type={'preview'}
      title={'Previsualizar PDF'}
      onClick={() => handlers.handlePreview(gridRef)}
    />

  const graphicsItem = () =>
    <CustomIconButton
      type={'graphics'}
      title={'Ver tipos'}
      onClick={handlers.handleOpenTypeDialog}
    />

  const addItem = () =>
    <CustomIconButton
      type={'add'}
      title={getLocalizedString("iconNewAnnex")}
      onClick={handlers.handleOpenCreateDialog}
    />

  const editItem = (gridRef) =>
    <CustomIconButton
      type={'edit'}
      title={getLocalizedString("edit")}
      onClick={() => handlers.handleOpenEditDialog(gridRef)}
    />

  const deleteItem = (gridRef) =>
    <CustomIconButton
      type={'delete'}
      title={getLocalizedString("delete")}
      onClick={() => handlers.handleOpenDeleteDialog(gridRef)}
    />

  const dataTreeButton = (gridRef) => (
    <CustomIconButton
      title={state.treeViewIsOpen ? "Ver como lista" : "Ver como árbol"}
      onClick={() => handlers.handleGrouping(gridRef)}
    >
      <AccountTreeIcon />
    </CustomIconButton>
  )

  const filterItem = () =>
    <>
      <FormControl style={{ width: 200, marginRight: 10 }}>
        <InputLabel shrink id={'label-type'}>{'Línea de tiempo'}</InputLabel>
        <Select
          labelId={'label-type'}
          id={'select-type'}
          value={year}
          onChange={e => setYear(e.target.value)}
          displayEmpty
        >
          <MenuItem value="">{'Desactivada'}</MenuItem>
          {
            yearsArray.map(item => (
              <MenuItem key={item.key} value={item.key}>
                {item.value}
              </MenuItem>
            ))
          }
        </Select>
      </FormControl>
      <FormControlLabel
        control={
          <Switch
            checked={state.filterIsOff}
            onChange={() => handlers.setFilterIsOff(prev => !prev)}
            value={state.filterIsOff}
            color="primary"
          />
        }
        label={state.filterIsOff ? getLocalizedString("allAnnexes") : `${getLocalizedString("annexesByDate")}: ${userDates["Fecha contratacion"]}`}
        labelPlacement="start"
        style={{ margin: 0 }}
      />
    </>

  const helpItem = (
    <MenuItemWithIcon
      key={getLocalizedString("annex")}
      value={"https://www.youtube.com/embed/VSHmIcykkvo"}
      text={getLocalizedString("annex")}
      type={"video"}
    />
  )

  return (
    <>
      <div style={{ display: showTimeline ? "block" : "none" }}>
        <CommonPage
          title={title}
          isLoading={isLoading}
          menuItems={[goBackItem]}
          customHeader={filterItem}
          isNotGridPage
        >
          <List className="list-round-container" style={{ overflowX: 'hidden', marginBottom: 10 }}>
            <Timeline
              data={arrayToRender}
              getAnnexInstanceName={getAnnexInstanceName}
            />
          </List>
        </CommonPage>
      </div>
      <div style={{ display: !showTimeline ? "block" : "none" }}>
        <CommonPage
          title={title}
          isLoading={isLoading}
          menuItems={[goBackItem, addItem, editItem, deleteItem, previewItem, downloadItem, graphicsItem, dataTreeButton]}
          helpItems={[helpItem]}
          customHeader={filterItem}
          columnDefPage={'annexesPage'}
          rowData={arrayToRender}
          hasHelp
          hasExpand
        />
      </div>
      {
        state.createDialogIsOpen &&
        <CreateEditDialog
          open={state.createDialogIsOpen}
          types={types}
          instances={instances}
          employeeCode={employeeCode}
          handleClose={handlers.handleCloseCreateDialog}
        />
      }
      {
        state.editDialogIsOpen &&
        <CreateEditDialog
          open={state.editDialogIsOpen}
          annex={state.annex}
          types={types}
          instances={instances}
          employeeCode={employeeCode}
          handleClose={handlers.handleCloseEditDialog}
        />
      }
      {
        state.typeDialogIsOpen &&
        <TypesDialog
          open={state.typeDialogIsOpen}
          handleOpenAnnexList={handlers.handleOpenInstanceDialog}
          handleClose={handlers.handleCloseTypeDialog}
          filterIsOff={state.filterIsOff}
          types={types}
          instances={instances}
          getAnnexTypeName={getAnnexTypeName}
          getAnnexInstanceName={getAnnexInstanceName}
        />
      }
      {
        Boolean(state.listInstance) &&
        <InstanceListDialog
          open={Boolean(state.listInstance)}
          handleClose={handlers.handleCloseInstanceDialog}
          instance={state.listInstance}
          types={types[state.listInstance].filter(tipo => setAnnexesTypeToShow(tipo))}
          exists={state.exists}
          getAnnexTypeName={getAnnexTypeName}
        />
      }
    </>
  );
}

export default AnnexesPage;
