import NAME from "./constants";

export const CLEAR_ANNEXES = NAME + "/CLEAR_ANNEXES";

export const GET_ANNEXES_ROWS = NAME + "/GET_ANNEXES_ROWS";
export const GET_ANNEXES_ROWS_REJECTED = NAME + "/GET_ANNEXES_ROWS_REJECTED";
export const GET_ANNEXES_ROWS_FULFILLED = NAME + "/GET_ANNEXES_ROWS_FULFILLED";

export const CREATE_ANNEX = NAME + "/CREATE_ANNEX";
export const CREATE_ANNEX_FULFILLED = NAME + "/CREATE_ANNEX_FULFILLED";
export const CREATE_ANNEX_REJECTED = NAME + "/CREATE_ANNEX_REJECTED";

export const DELETE_ANNEX = NAME + "/DELETE_ANNEX";
export const DELETE_ANNEX_FULFILLED = NAME + "/DELETE_ANNEX_FULFILLED";
export const DELETE_ANNEX_REJECTED = NAME + "/DELETE_ANNEX_REJECTED";

export const UPDATE_ANNEX = NAME + "/UPDATE_ANNEX";
export const UPDATE_ANNEX_FULFILLED = NAME + "/UPDATE_ANNEX_FULFILLED";
export const UPDATE_ANNEX_REJECTED = NAME + "/UPDATE_ANNEX_REJECTED";

export const DOWNLOAD_ANNEX = NAME + "/DOWNLOAD_ANNEX";
export const DOWNLOAD_ANNEX_FULFILLED = NAME + "/DOWNLOAD_ANNEX_FULFILLED";
export const DOWNLOAD_ANNEX_REJECTED = NAME + "/DOWNLOAD_ANNEX_REJECTED";

export const DOWNLOAD_ANNEX_FOLDER = NAME + "/DOWNLOAD_ANNEX_FOLDER";
export const DOWNLOAD_ANNEX_FOLDER_FULFILLED = NAME + "/DOWNLOAD_ANNEX_FOLDER_FULFILLED";
export const DOWNLOAD_ANNEX_FOLDER_REJECTED = NAME + "/DOWNLOAD_ANNEX_FOLDER_REJECTED";

export const UPDATE_ANNEX_TYPES = NAME + "/UPDATE_ANNEX_TYPES";