import React from "react";
import { Dialog, DialogTitle, DialogContent, Typography } from "@material-ui/core";
import DialogTitleDivider from "@icarius-common/dialogTitleDivider";
import PaperDraggable from "@icarius-common/paperDraggable";
import DialogTransition from "@icarius-common/dialogTransition";
import { CloseIcon } from "@icarius-icons";
import Charts from "../charts";

const TypesDialog = (props) => {

  const {
    open,
    handleClose,
    types,
    handleOpenAnnexList,
    instances,
    getAnnexInstanceName,
  } = props;

  return (
    <Dialog
      TransitionComponent={DialogTransition}
      PaperComponent={PaperDraggable}
      open={open}
      maxWidth={"sm"}
      fullWidth={true}
    >
      <div className={"dialog-container"}>
        <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
          {'Tipos de archivo'}
          <DialogTitleDivider />
        </DialogTitle>
        <CloseIcon className={"dialog-close-icon icon"} onClick={handleClose} />
        <DialogContent style={{ paddingTop: "0px" }}>
          <Typography className="whiteText">
            {"Haga clic en los gráficos para ver el detalle"}
          </Typography>
          <Charts
            types={types}
            instances={instances}
            handleOpenAnnexList={handleOpenAnnexList}
            getAnnexInstanceName={getAnnexInstanceName}
          />
        </DialogContent>
      </div>
    </Dialog>
  );
}

export default TypesDialog;
