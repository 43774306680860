import React from "react";
import ScreenSelector from "@icarius-common/screenSelector";
import { DiseasesIcon, ExamsIcon, AllergiesIcon } from "@icarius-icons/";
import { getLocalizedString } from "@icarius-localization/strings";
import paths from "@icarius-localization/paths";

const Index = () => {

  const data = [
    {
      name: "Alergias",
      icon: <AllergiesIcon />,
      path: paths.allergiesSettings,
    },
    {
      name: "Exámenes médicos",
      icon: <ExamsIcon />,
      path: paths.examsSettings,
    },
    {
      name: "Enfermedades",
      icon: <DiseasesIcon />,
      path: paths.diseasesSettings,
    },
  ];

  return (
    <ScreenSelector
      title={getLocalizedString("allergiesExamsDiseases")}
      data={data}
    />
  );
}

export default Index;
