import React from "react";
import { Typography } from "@material-ui/core";

const AnnotationText = (props) => {

  const {
    isBold,
    useColor,
    color,
    shouldtruncate,
    text,
  } = props;

  return (
    <div style={{ height: 28 }}>
      <Typography
        style={{ fontWeight: isBold ? "bold" : 100, color: useColor && color }}
        variant="subtitle1"
        className={`todo-title ${shouldtruncate ? "truncate" : ""} ${useColor ? "" : "whiteText"}`}>
        {text}
      </Typography>
    </div>
  );
}

export default AnnotationText;