import React from "react";
import { Typography, ExpansionPanel, ExpansionPanelSummary, ExpansionPanelDetails } from "@material-ui/core";
import { ExpandMoreIcon } from "@icarius-icons";
import AnnotationText from "../annotationText";
import { withStyles } from "@material-ui/core/styles";
import { getCategoryColor } from "@icarius-utils/colors";
import { getLocalizedString } from "@icarius-localization/strings";
import { useSelector } from "react-redux";
import { getTheme } from "@icarius-pages/login/selectors";

const CustomExpansionPanelDetails = withStyles({
  root: {
    paddingTop: 0,
  },
})(ExpansionPanelDetails);

const CustomExpansionPanel = withStyles({
  root: {
    backgroundColor: "var(--secondaryBackgroundColor)",
  },
})(ExpansionPanel);

const ExpansionContainer = (props) => {

  const {
    title,
    content,
    noTruncate,
    isExpanded,
    isTitle,
    categoria,
    subtipo,
  } = props;

  const { theme } = useSelector(getTheme);

  const getColorText = () => {
    switch (categoria) {
      case "V":
        return "Verde";
      case "A":
        return "Amarilla";
      case "R":
        return "Roja";
      default:
        return "Sin Categoría";
    }
  };

  const getContent = () => {
    if (noTruncate) {
      return (
        <Typography variant="subtitle1" className="todo-title whiteText">
          {content}
        </Typography>
      )
    }

    if (isTitle) {
      return (
        <>
          <Typography variant="subtitle1" className="todo-title whiteText">
            {content + " -"}
          </Typography>
          <div> &nbsp; </div>
          <Typography variant="subtitle1" className="todo-title" style={{ color: getCategoryColor(categoria, theme, false) }}>
            {getColorText()}
          </Typography>
          <div> &nbsp; </div>
          <Typography variant="subtitle1" className="todo-title whiteText">
            {"- " + (subtipo || getLocalizedString("noSubtype"))}
          </Typography>
        </>
      )
    }

    return <AnnotationText text={content} />
  }

  return (
    <CustomExpansionPanel defaultExpanded={isExpanded}>
      <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
        <AnnotationText text={title} />
      </ExpansionPanelSummary>
      <CustomExpansionPanelDetails>
        {getContent()}
      </CustomExpansionPanelDetails>
    </CustomExpansionPanel>
  );
};

export default ExpansionContainer;
