import * as actionTypes from "./actionTypes";
import { openSnackbarAction } from "@icarius-common/snackbar/actions";
import { getErrorStringFromError } from "@icarius-localization/errors";
import { getAnnotationAnalysisAPI } from "@icarius-connection/api";

export const getAnnotationDataFromServer = () => async (dispatch) => {
  dispatch({ type: actionTypes.GET_ROWS });
  try {
    let response = await getAnnotationAnalysisAPI();

    let rows = response.data && response.data.annotations;
    let dateFormat = response.data && response.data.date_format;
    let types = response.data && response.data.types;
    let subtypes = response.data && response.data.subtypes;

    dispatch({
      type: actionTypes.GET_ROWS_FULFILLED,
      payload: { rows, dateFormat, types, subtypes }
    });
    return response;
  } catch (e) {
    dispatch({ type: actionTypes.GET_ROWS_REJECTED, payload: e });
    dispatch(openSnackbarAction({ msg: getErrorStringFromError(e), severity: "error" }));
  }
};