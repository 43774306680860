import NAME from "./constants";

export const getIsLoading = store => store[NAME].isLoading;

export const getAnnotationRows = store => store[NAME].annotations;
export const getCategories = store => store[NAME].categories;
export const getAnnotationTypes = store => store[NAME].types;
export const getSubtypes = store => store[NAME].subtypes;

export const getAnnexesRows = store => store[NAME].annexes;
export const getInstances = store => store[NAME].instances;
export const getAnnexesTypes = store => store[NAME].annexestypes;

export const getUserDates = store => store[NAME].userDates;