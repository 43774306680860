import React from "react";
import { splitIntoLines } from "@icarius-utils/format";
import { Doughnut } from 'react-chartjs-2';
import { useSelector } from "react-redux";
import { getTheme } from "@icarius-pages/login/selectors";

const DoughnutChart = (props) => {

  const { theme } = useSelector(getTheme);
  const {
    data,
    handleOpenAnnexList,
  } = props;

  const options = {
    responsive: true,
    title: {
      display: true,
      fontColor: theme === "dark" ? "#FFF" : "black",
      fontSize: 12,
      text: data?.title || "",
    },
    onHover: (event, chartElement) => {
      event.target.style.cursor = chartElement[0] ? "pointer" : "default";
    },
    onClick: (evt, item) => {
      if (item.length > 0) {
        const selectedIndex = item[0]._index;

        if (selectedIndex !== -1) {
          handleOpenAnnexList(data.type, selectedIndex);
        }
      }
    },
    maintainAspectRatio: false,
    legend: {
      display: false,
    },
    layout: {
      padding: {
        bottom: 0,
        top: 0,
      },
    },
    tooltips: {
      displayColors: false,
      mode: "single",
      callbacks: {
        label: function (tooltipItem, data) {
          let valores = splitIntoLines(data.labels[tooltipItem.index], 15);
          valores[valores.length - 1] +=
            ": " + data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];
          return valores;
        },
      },
    },
  };

  return (
    <div className={"container h-100 sm:h-100 relative flex flex-col justify-center items-center"}>
      <div className="principal chart-wrapper">
        <div style={{ height: 120 }} className="container  h-100 sm:h-100  justify-between items-center">
          <Doughnut
            data={data}
            options={options}
            width={120}
            height={120}
          />
        </div>
      </div>
    </div>
  );
}

export default DoughnutChart;
