import { annexGraph } from "@icarius-utils/colors";

const getAnnexChartData = (types, instances, getAnnexInstanceName) => {

  const fillDataStructure = (labels, values, color, title, type) => {
    return {
      labels: labels,
      datasets: [
        {
          data: values,
          backgroundColor: color.fills,
          borderColor: color.strokes,
          borderWidth: 1,
        },
      ],
      title: title,
      type: type
    }
  }

  const createDataForAnnexChart = (index) => {
    const typesToCheck = types[instances[index].key];
    let amountHas = 0;
    let amountDoesNotHave = 0;
    let amountDoesNotHaveNotRequired = 0;

    typesToCheck.forEach(type => {
      if (type.exists) {
        amountHas++;
        return;
      }
      
      if (type.required === "Y") {
        amountDoesNotHave++;
        return;
      }
      
      amountDoesNotHaveNotRequired++;
    });

    return fillDataStructure(
      ['Contiene', 'Faltantes', 'No existentes'],
      [amountHas, amountDoesNotHave, amountDoesNotHaveNotRequired],
      annexGraph,
      getAnnexInstanceName(instances[index].key),
      instances[index].key,
    )
  };

  return [
    createDataForAnnexChart(1),
    createDataForAnnexChart(0),
    createDataForAnnexChart(2)
  ]
}

export default getAnnexChartData;