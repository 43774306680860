import React, { useState, useReducer, useMemo } from "react";
import AnnotationsListItem from "./annotationsListItem";
import { withStyles } from "@material-ui/core/styles";
import { AddIcon } from "@icarius-icons";
import { getLocalizedString } from "@icarius-localization/strings";
import { HOVER_OPACITY } from "@icarius-utils/constants";
import { hexToRgb } from "@icarius-utils/colors";
import { createDateFromDDMMYYYY } from "@icarius-utils/date";
import { useSelector } from "react-redux";
import { getTheme } from "@icarius-pages/login/selectors";
import { getAppColor } from "src/app/selectors";
import { getAnnotationTypes, getUserDates } from "@icarius-pages/annotationsPage/selectors";
import {
  List,
  Fab,
  FormControl,
  Select,
  MenuItem,
  Typography,
  Grid,
  Button,
} from "@material-ui/core";
import DialogContainer from "./dialogContainer";
import Timeline from "./timeline";
import useAnnotationYear from "./useAnnotationYear";

const CustomSelect = withStyles({
  select: {
    maxWidth: 300,
    minWidth: 200,
  },
})(Select);

const initialState = {
  annotation: null,
  newAnnotationDialogIsOpen: false,
  editAnnotationDialogIsOpen: false,
  viewAnnotationDialogIsOpen: false,
  deleteAnnotationDialogIsOpen: false,
};

function reducer(state, action) {
  switch (action.type) {
    case "openNew":
      return { ...state, annotation: null, newAnnotationDialogIsOpen: true };
    case "closeNew":
      return { ...state, annotation: null, newAnnotationDialogIsOpen: false };
    case "openEdit":
      return { ...state, annotation: action.payload, editAnnotationDialogIsOpen: true };
    case "closeEdit":
      return { ...state, annotation: null, editAnnotationDialogIsOpen: false };
    case "openView":
      return { ...state, annotation: action.payload, viewAnnotationDialogIsOpen: true };
    case "closeView":
      return { ...state, annotation: null, viewAnnotationDialogIsOpen: false };
    case "openDelete":
      return { ...state, annotation: action.payload, deleteAnnotationDialogIsOpen: true };
    case "closeDelete":
      return { ...state, annotation: null, deleteAnnotationDialogIsOpen: false };
    default:
      throw new Error();
  }
}

const ListContainer = (props) => {

  const {
    data,
    filterIsOff,
    getSubtypeName,
    initialYear,
  } = props;

  const [state, dispatch] = useReducer(reducer, initialState);
  const [annotationType, setAnnotationType] = useState("");

  const {
    annotation,
    newAnnotationDialogIsOpen,
    editAnnotationDialogIsOpen,
    viewAnnotationDialogIsOpen,
    deleteAnnotationDialogIsOpen,
  } = state;

  const {
    year,
    setYear,
    yearsArray,
  } = useAnnotationYear(data, initialYear);

  const theme = useSelector(getTheme);
  const companyColor = useSelector(getAppColor);
  const types = useSelector(getAnnotationTypes);
  const userDates = useSelector(getUserDates);

  const CustomFab = withStyles({
    root: {
      color: theme.theme === "dark" ? "white" : "black",
      backgroundColor: theme.theme === "dark" && "#222222",
      "&:hover": {
        backgroundColor: hexToRgb(companyColor, HOVER_OPACITY),
      },
    },
  })(Fab);

  const filteredData = useMemo(() => {
    let aux = [...data];

    //filtrar segun año seleccionado
    if (year !== "") {
      aux = aux.filter(annotation => {
        const annotationYear = createDateFromDDMMYYYY(annotation["Fecha de registro"]).getFullYear();
        const formattedSelectedYear = parseInt(year);
        return annotationYear === formattedSelectedYear;
      })
    }

    //filtrar segun fecha contratacion
    if (!filterIsOff) {
      aux = aux.filter(annotation => createDateFromDDMMYYYY(annotation["Fecha de registro"]) >= createDateFromDDMMYYYY(userDates["Fecha contratacion"]))
    }

    //filtrar segun tipo seleccionado
    if (annotationType !== "") {
      aux = aux.filter(annotation => annotation["Tipo"] === annotationType)
    }

    return aux;
  }, [data, filterIsOff, annotationType, userDates, year])

  const hasData = data?.length > 0;
  const hasFilteredData = filteredData && filteredData.length > 0;

  const getContentToRender = () => {
    if (!hasFilteredData) {
      return (
        <Typography style={{ paddingLeft: 20 }} className="whiteText" variant="h6">
          {getLocalizedString("noAnnotationsOfThisType")}
        </Typography>
      )
    }

    if (year !== '') {
      return (
        <Timeline
          data={filteredData}
          getSubtypeName={getSubtypeName}
        />
      )
    }

    return (
      filteredData.map(item => (
        <AnnotationsListItem
          gridTheme={theme.theme}
          color={companyColor}
          item={item}
          key={item.Codigo}
          getSubtypeName={getSubtypeName}
          handleViewAnnotation={(annotation) => dispatch({ type: "openView", payload: annotation })}
          handleOpenDeleteDialog={(annotation) => dispatch({ type: "openDelete", payload: annotation })}
          handleOpenEditDialog={(annotation) => dispatch({ type: "openEdit", payload: annotation })}
        />
      ))
    )
  }

  return (
    <>
      {
        !hasData &&
        <Grid container direction="column" justify="center" alignItems="center" style={{ padding: 20 }}>
          <Grid item>
            <h1 className="whiteText">{getLocalizedString("noAnnotations")}</h1>
          </Grid>
          <Grid item>
            <CustomFab
              onClick={() => dispatch({ type: "openNew" })}
              variant="extended"
              size={"small"}
              aria-label="add"
              style={{ cursor: "pointer" }}>
              <AddIcon htmlColor={companyColor} />
              {getLocalizedString("iconNewAnnotation")}
            </CustomFab>
          </Grid>
        </Grid>
      }
      {
        hasData &&
        <div style={{ position: "relative", marginBottom: 10, marginTop: 10 }}>
          <Grid container justify='flex-start'>
            <List className="h-100 backgroundSecondary listAnnotations" style={{ width: "300px", margin: 0, marginBottom: 10, marginRight: 10 }}>
              <Grid container justify="center" alignItems="center">
                <FormControl style={{ width: "90%" }}>
                  <CustomSelect
                    id="demo-simple-select"
                    value={annotationType}
                    onChange={e => setAnnotationType(e.target.value || "")}
                    displayEmpty>
                    <MenuItem value="">{getLocalizedString("selectType")}</MenuItem>
                    {
                      types.map((item, index) => (
                        <MenuItem key={index} value={item.FldValue}>
                          {item.Descr}
                        </MenuItem>
                      ))
                    }
                  </CustomSelect>
                </FormControl>
              </Grid>
            </List>
            <List className="h-100 backgroundSecondary listAnnotations" style={{ width: "300px", margin: 0, marginBottom: 10, marginRight: 10 }}>
              <Grid container justify="center" alignItems="center">
                <FormControl style={{ width: "90%" }}>
                  <CustomSelect
                    id="demo-simple-select"
                    value={year}
                    onChange={e => setYear(e.target.value)}
                    displayEmpty>
                    <MenuItem value="">{'Todas las anotaciones'}</MenuItem>
                    {
                      yearsArray.map(item => (
                        <MenuItem key={item.key} value={item.key}>
                          {item.value}
                        </MenuItem>
                      ))
                    }
                  </CustomSelect>
                </FormControl>
              </Grid>
            </List>
            <Button
              className="whiteText"
              onClick={() => dispatch({ type: "openNew" })}
              variant='contained'
              style={{
                marginBottom: 10,
                marginRight: 10,
                backgroundColor: 'var(--secondaryBackgroundColor)',
                border: '1px solid var(--listAnnotation)'
              }}
              startIcon={<AddIcon htmlColor={companyColor} />}
            >
              {getLocalizedString("iconNewAnnotation")}
            </Button>
          </Grid>
          <List className="list-round-container" style={{ overflowX: 'hidden' }}>
            {getContentToRender()}
          </List>
        </div>
      }
      <DialogContainer
        annotation={annotation}
        annotationType={annotationType}
        viewAnnotationDialogIsOpen={viewAnnotationDialogIsOpen}
        deleteAnnotationDialogIsOpen={deleteAnnotationDialogIsOpen}
        newAnnotationDialogIsOpen={newAnnotationDialogIsOpen}
        editAnnotationDialogIsOpen={editAnnotationDialogIsOpen}
        handleCloseNewDialog={() => dispatch({ type: "closeNew" })}
        handleCloseViewDialog={() => dispatch({ type: "closeView" })}
        handleCloseDeleteDialog={() => dispatch({ type: "closeDelete" })}
        handleCloseEditDialog={() => dispatch({ type: "closeEdit" })}
        getSubtypeName={getSubtypeName}
      />
    </>
  )
}

export default ListContainer;
