import React, { useState, useRef, useEffect } from "react";
import { Chart } from "react-chartjs-2";
import { meritoDemerito, randomColors } from "@icarius-utils/colors";
import { useSelector } from "react-redux";
import { getTheme } from "@icarius-pages/login/selectors";

const VerticalBarChart = (props) => {

  const { data } = props;
  const { theme } = useSelector(getTheme);

  const canvasRef = useRef();
  const [ myChart, setMyChart ] = useState();

  useEffect(() => {
    if(myChart){
      myChart.update();
    }
  })

  useEffect(() => {
    let info = {
      labels: Object.keys(data.data),
      datasets: [
        {
          pointHoverRadius: 5,
          pointRadius: 3,
          fontColor: "#00FF00",
          pointHitRadius: 10,
          data: Object.values(data.data),
          borderColor: data.type === "type" ? meritoDemerito.strokes : randomColors.strokes,
          backgroundColor: data.type === "type" ? meritoDemerito.fills : randomColors.fills,
          borderWidth: 1,
        },
      ],
    };

    const options = {
      responsive: true,
      title: {
        display: true,
        fontColor: theme === "dark" ? "#FFF" : "black",
        fontSize: 12,
        text: data.title,
      },
      maintainAspectRatio: false,
      legend: {
        display: false,
        position: "bottom",
        labels: {
          fontColor: theme === "dark" ? "#FFF" : "black",
          fontSize: 12,
        },
      },
      layout: {
        padding: {
          bottom: 0,
          top: 0,
        },
      },
      tooltips: {
        displayColors: false,
        mode: "single",
        callbacks: {
          label: function(tooltipItem, data) {
            return `${data.labels[tooltipItem.index]}: ${data.datasets[0].data[tooltipItem.index]}`;
          },
        },
      },
    };

    const chartData = {
      type: "pie",
      options: options,
      data: info,
    };

    setMyChart(new Chart(canvasRef.current, chartData));
  }, [data, theme])

  return (
    <div className={"container h-100 sm:h-100 relative flex flex-col justify-center items-center"}>
      <div className="principal chart-wrapper">
        <div style={{ height: 300 }} className="container  h-100 sm:h-100  justify-between items-center">
          <canvas ref={canvasRef} />
        </div>
      </div>
    </div>
  );
}

export default VerticalBarChart;
