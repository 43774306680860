import React from "react";
import { CloseIcon } from "@icarius-icons";
import { withStyles } from "@material-ui/core/styles";
import { DialogContent, Dialog, DialogTitle } from "@material-ui/core";
import ExpansionContainer from "./ExpansionContainer";
import { getLocalizedString } from "@icarius-localization/strings";
import PaperDraggable from "@icarius-common/paperDraggable";
import { getAnnotationTypes } from "@icarius-pages/annotationsPage/selectors";
import { getMyAnnotationTypes } from "@icarius-pages/myAnnotations/selectors";
import { useSelector } from "react-redux";
import DialogTransition from "@icarius-common/dialogTransition";
import { formatDate, getLocalTimeFromUTCStringDateAndStringTime } from "@icarius-utils/date";

const StyledDialog = withStyles({
  paper: {
    maxWidth: "80%",
    minWidth: "80%",
  },
})(Dialog);

const ViewAnnotationDialog = (props) => {

  const {
    open,
    annotation,
    isMyAnnotation,
    handleClose,
  } = props;
  
  const types = useSelector(!isMyAnnotation ? getAnnotationTypes : getMyAnnotationTypes);

  const getTypeName = (tipo) => {
    return types.filter(type => type.FldValue === tipo)[0]["Descr"];
  };

  const visibilityText = annotation["Visnote"] === "Y" ? getLocalizedString("visibleColaborador") : getLocalizedString("invisibleColaborador");

  const localDate = getLocalTimeFromUTCStringDateAndStringTime(annotation["Fecha de registro"], annotation['Hora de registro']);

  return (
    <StyledDialog
      TransitionComponent={DialogTransition}
      PaperComponent={PaperDraggable}
      open={open}
      scroll={"paper"}
      fullWidth={true}
      maxWidth={"lg"}>
      <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
        {`${getTypeName(annotation["Tipo"])} por ${annotation["Nombre registro"]}`}
      </DialogTitle>
      <CloseIcon className={"dialog-close-icon icon"} onClick={handleClose} />
      <DialogContent style={{ paddingTop: 0, paddingBottom: 20 }}>
        <ExpansionContainer
          isTitle
          isExpanded
          title={getLocalizedString("viewAnnotationTitle")}
          content={annotation["Codigo"]}
          categoria={annotation["Categoria"]}
          subtipo={props.getSubtypeName(annotation["Tipo"], annotation["Sub"])}
        />
        <ExpansionContainer
          isExpanded
          title={getLocalizedString("viewAnnotationTime")}
          content={`${formatDate(localDate)} ${localDate.toTimeString().split(' ')[0].substring(0, 5)} hrs`}
        />
        {
          !isMyAnnotation &&
          <ExpansionContainer
            isExpanded
            title={getLocalizedString("viewAnnotationVisibility")}
            content={`${visibilityText}`}
          />
        }
        <ExpansionContainer
          noTruncate
          isExpanded
          title={getLocalizedString("viewAnnotationContent")}
          content={annotation["Texto"]}
        />
      </DialogContent>
    </StyledDialog>
  );
}
export default ViewAnnotationDialog;
