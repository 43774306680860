import React from "react";
import { getLocalizedString } from "@icarius-localization/strings";
import ViewAnnotationDialog from "./viewAnnotations/viewAnnotationDialog";
import ConfirmationDialog from "@icarius-pages/myPeople/components/dialog/confirmationDialog";
import NewAnnotationDialog from "./newAnnotations/newAnnotationDialog";
import { useDispatch } from "react-redux";
import {
  deleteAnnotationAction,
  createAnnotationAction,
  updateAnnotationAction,
} from "../../actions";

const DialogContainer = (props) => {

  const {   
    annotation,
    annotationType,
    viewAnnotationDialogIsOpen, 
    deleteAnnotationDialogIsOpen, 
    newAnnotationDialogIsOpen, 
    editAnnotationDialogIsOpen,    
    handleCloseViewDialog,
    handleCloseDeleteDialog,
    handleCloseNewDialog,
    handleCloseEditDialog,
    getSubtypeName,
  } = props;

  const dispatch = useDispatch();

  const handleDelete = (code) => {
    dispatch(deleteAnnotationAction(code));
  };

  const handleCreate = (annotation) => {
    dispatch(createAnnotationAction(annotation));
  };

  const handleUpdate = (annotation) => {
    dispatch(updateAnnotationAction(annotation));
  };

  return (
    <>
      {
        viewAnnotationDialogIsOpen &&
        <ViewAnnotationDialog
          open={viewAnnotationDialogIsOpen}
          annotation={annotation}
          getSubtypeName={getSubtypeName}            
          handleClose={handleCloseViewDialog}
        />
      }
      {
        deleteAnnotationDialogIsOpen &&
        <ConfirmationDialog
          open={deleteAnnotationDialogIsOpen}
          handleConfirm={handleDelete}
          handleClose={handleCloseDeleteDialog}
          code={annotation["Codigo"]}
          text={getLocalizedString("annotationsConfirm")}
        />
      }
      {
        newAnnotationDialogIsOpen &&
        <NewAnnotationDialog
          open={newAnnotationDialogIsOpen}
          type={annotationType}
          handleClose={handleCloseNewDialog}
          handleProcess={handleCreate}
        />
      }
      {
        editAnnotationDialogIsOpen &&
        <NewAnnotationDialog
          open={editAnnotationDialogIsOpen}
          data={annotation}
          handleClose={handleCloseEditDialog}
          handleProcess={handleUpdate}
        />
      }
    </>
  );
}

export default DialogContainer;
