import React from "react";
import { CloseIcon } from "@icarius-icons";
import DialogTitleDivider from "@icarius-common/dialogTitleDivider";
import PaperDraggable from "@icarius-common/paperDraggable";
import DialogTransition from "@icarius-common/dialogTransition";
import { getFileName, getFileExtension, makeFile } from "@icarius-utils/fileUpload";
import { withStyles } from "@material-ui/core/styles";
import {
  Dialog,
  DialogTitle,
  Typography,
  DialogContent,
  Button,
  DialogActions,
  MenuItem,
  Grid,
} from "@material-ui/core";
import { getLocalizedString } from "@icarius-localization/strings";
import { getTomorrow, formatDate, createDateFromDDMMYYYY } from "@icarius-utils/date";
import { Switches, TextField, Select, DatePicker } from "mui-rff";
import { Form, Field } from "react-final-form";
import DateFnsUtils from "@date-io/date-fns";
import esLocale from "date-fns/locale/es";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import createDecorator from 'final-form-calculate'
import ButtonDialogAction from "@icarius-common/buttonDialogAction";
import { useDispatch } from "react-redux";
import { createAnnexInServer, updateAnnexInServer } from "@icarius-pages/annexesPage/actions";

const StyledDialog = withStyles({
  paper: {
    minWidth: "45%",
  },
})(Dialog);

const CreateEditDialog = (props) => {

  const {
    open,
    employeeCode,
    instances,
    types,
    annex,
    handleClose,
    isMyAnnexes,
  } = props;

  const isEdit = Boolean(annex);
  const dispatch = useDispatch();

  const textIsValid = (values) => {
    return values.descripcion && values.descripcion.length > 0 && values.descripcion.length <= 250;
  };

  const dateIsValid = (values) => {
    const hoy = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate());
    return values.date === "" || values.date === null || values.date > hoy;
  };

  const dataIsComplete = (values) => {
    if (values.Instancia && values.Tipo && (isEdit || values.file) && values.fileName && values.fileExtension && textIsValid(values)) {
      return true;
    }

    if (values.Instancia === "OT" && (isEdit || values.file) && values.fileName && values.fileExtension && textIsValid(values)) {
      return true;
    }

    return false;
  };

  const validateAndCreate = (values) => {
    if (textIsValid(values) && dataIsComplete(values) && dateIsValid(values)) {
      const annexToSend = {
        Code: annex ? annex.Codigo : null,
        Codigo: employeeCode,
        inslab: values.Instancia,
        tipo: values.Tipo,
        texto: values.descripcion,
        file: values.file || null,
        extension: values.file ? values.fileExtension : null,
        nombre: values.file ? values.fileName + "." + values.fileExtension : null,
        fecha: values.date === null || values.date === "" ? "31/12/9999" : formatDate(values.date),
        colaborator_visible: values.switchVisibility,
      };

      if (isEdit) {
        delete annexToSend.Codigo;
      } else {
        delete annexToSend.Code;
      }

      dispatch(isEdit ? updateAnnexInServer(annexToSend) : createAnnexInServer(annexToSend));
      handleClose();
    }
  };

  const objInstancia = {
    name: "Instancia",
    data: instances,
  };

  const objTipo = {
    name: "Tipo",
    data: [],
  };

  const data = [objInstancia, objTipo];
  let initialValues;

  const Condition = ({ when, is, children }) => (
    <Field name={when} subscription={{ value: true }}>
      {({ input: { value } }) => (value === is ? children : null)}
    </Field>
  );

  if (annex) {
    initialValues = {
      descripcion: annex.Texto,
      fileName: getFileName(annex["Nombre archivo"]),
      fileExtension: getFileExtension(annex["Nombre archivo"]),
      Instancia: annex["Instancia"],
      date: annex["Fecha de vencimiento"] === "31/12/9999" ? "" : createDateFromDDMMYYYY(annex["Fecha de vencimiento"]),
      Tipo: annex["Tipo"],
      switchVisibility: annex.colaborator_visible,
      isFirstInstance: true,
    };
  } else {
    initialValues = {
      descripcion: "",
      Instancia: "",
      fileName: "",
      fileExtension: "",
      date: "",
      switchVisibility: true,
      isFirstInstance: true,
    };
  }

  const calculator = createDecorator(
    {
      field: 'Instancia',
      updates: {
        Tipo: (InstanciaValue, allValues) => {
          //Si ya hay annex (estoy editando), cuando setea la instancia por primera vez (isFirstInstance) setea el tipo que viene del back. Sino, "".
          //Idem si no estoy editando, pero en lugar de setear el tipo del back, seteo el tipo seleccionado en el filtro. Si no hay filtro, queda "".
          if (!annex) {
            if (allValues.isFirstInstance) {
              allValues.isFirstInstance = false;
              return props.annexType;
            }
            return "";
          } else {
            if (allValues.isFirstInstance) {
              allValues.isFirstInstance = false;
              return initialValues.Tipo;
            }
            return "";
          }
        }
      }
    },
  )

  return (
    <StyledDialog
      TransitionComponent={DialogTransition}
      PaperComponent={PaperDraggable}
      open={open}
      scroll={"paper"}
      fullWidth={true}
      maxWidth={"md"}>
      <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
        {isEdit ? getLocalizedString("iconEditAnnex") : getLocalizedString("iconNewAnnex")}
        <DialogTitleDivider />
      </DialogTitle>
      <CloseIcon className={"dialog-close-icon icon"} onClick={handleClose} />
      <Form
        mutators={{
          setValue: ([field, value], state, { changeValue }) => {
            changeValue(state, field, () => value);
          },
        }}
        initialValues={initialValues}
        onSubmit={validateAndCreate}
        decorators={[calculator]}
        render={({ handleSubmit, form, submitting, pristine, values, active }) => (
          <form onSubmit={handleSubmit} noValidate>
            <DialogContent style={{ paddingTop: 0 }}>
              <Grid container direction="column" justify="center">
                <Typography
                  className={!dataIsComplete(values) ? "errorColor" : "whiteText"}
                  variant="caption"
                  gutterBottom>
                  {getLocalizedString("fieldRequeriment")}
                </Typography>
                <Grid container direction="row" justify="center" item>
                  <Grid item xs={12}>
                    <Select
                      label={getLocalizedString("instance")}
                      name={data[0].name}
                      formControlProps={{ margin: "normal" }}
                    >
                      {
                        data[0].name !== "Tipo" &&
                        data[0].data.map((subItem) => (
                          <MenuItem
                            className={"whiteText"}
                            key={subItem.key}
                            value={subItem.key}>
                            {subItem.value}
                          </MenuItem>
                        ))
                      }
                    </Select>
                  </Grid>
                </Grid>
                {/* Cuando no hay Tipo seleccionado...*/}
                <Condition when={data[0].name} is={""}>
                  <Grid container direction="row" justify="center" item>
                    <Grid item xs={12}>
                      <Select
                        disabled
                        label={getLocalizedString("type")}
                        name={"select-vacio"}
                        formControlProps={{ margin: "normal" }}
                      >
                        <MenuItem className={"whiteText"} value={""}>
                          {""}
                        </MenuItem>
                      </Select>
                    </Grid>
                  </Grid>
                </Condition>
                {/*Cuando si hay tipo seleccionado...*/}
                {
                  Object.keys(types).map((item, index) => {
                    return (
                      <Condition key={index} when={data[0].name} is={item}>
                        <Grid key={index} container direction="row" justify="center" item>
                          <Grid item xs={12}>
                            <Select
                              disabled={
                                values.Instancia === "OT" ||
                                values.Instancia === null ||
                                values.Instancia === ""
                              }
                              label={getLocalizedString("type")}
                              name={"Tipo"}
                              formControlProps={{ margin: "normal" }}
                            >
                              {
                                values.Instancia &&
                                types[item].map((subItem) => (
                                  <MenuItem
                                    className={"whiteText"}
                                    key={subItem.key}
                                    value={subItem.key}>
                                    {subItem.value}
                                  </MenuItem>
                                ))
                              }
                            </Select>
                          </Grid>
                        </Grid>
                      </Condition>
                    );
                  })
                }
                <Grid style={{ paddingTop: 16 }} container direction="row" justify="flex-start" item>
                  <Grid container direction="row" item xs={12}>
                    <TextField
                      label={getLocalizedString("enterText")}
                      id="fieldToFocus"
                      name={"descripcion"}
                      margin={"none"}
                      onInput={e => {
                        e.target.value = e.target.value.toString().slice(0, 250);
                      }}
                      multiline={true}
                      rows={1}
                      rowsMax={3}
                    />
                    <Typography
                      style={{ paddingTop: 5 }}
                      className={
                        !values.descripcion ||
                          values.descripcion.length === 0 ||
                          values.descripcion.length > 250
                          ? "errorColor"
                          : "whiteText"
                      }
                      variant="caption"
                      gutterBottom
                    >
                      {`${getLocalizedString("currentChar")} ${values.descripcion ? values.descripcion.length : 0
                        }. ${getLocalizedString("maxCharAnnex")}.`}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container direction="row" justify="center" alignItems="center" item>
                  <Grid item xs={12}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
                      <DatePicker
                        label={getLocalizedString("annexFechaVencimiento")}
                        clearable
                        clearLabel={getLocalizedString("clear")}
                        cancelLabel={getLocalizedString("cancel")}
                        okLabel={getLocalizedString("ok")}
                        disablePast
                        minDate={getTomorrow()}
                        format="dd/MM/yyyy"
                        margin="normal"
                        name="date"
                      />
                    </MuiPickersUtilsProvider>
                  </Grid>
                  <Grid container>
                    <Grid item xs={12}>
                      <Typography className={!dateIsValid(values) ? "errorColor" : "whiteText"} variant="caption" gutterBottom>
                        {getLocalizedString("annexDateHelper")}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid style={{ paddingTop: 15 }} container direction="row" justify="flex-start" item>
                  {
                    !isMyAnnexes &&
                    <Grid style={{ paddingBottom: 10 }} item xs={12} container direction="column" alignItems="flex-end" justify="center">
                      <Switches
                        color={"primary"}
                        formControlLabelProps={{ labelPlacement: "start" }}
                        name="switchVisibility"
                        required={true}
                        data={{
                          label: getLocalizedString("availableColaborador"),
                          value: true,
                        }}
                      />
                    </Grid>
                  }
                  <Grid container direction="column" alignItems="center" justify="center" item xs={12}>
                    <Grid item>
                      <input
                        type="file"
                        name={"file"}
                        id="fileInputUpload"
                        style={{ display: "none" }}
                        onChange={e => {
                          makeFile(e.target.files[0], myBase64 => {
                            values.file = myBase64;
                          });
                          values.fileName = getFileName(e.target.files[0].name);
                          values.fileExtension = getFileExtension(e.target.files[0].name);
                          setTimeout(function () {
                            document.getElementById("fieldToFocus").focus();
                          }, 300);
                        }}
                      />
                      <label htmlFor="fileInputUpload">
                        <Button
                          variant={"contained"}
                          disableRipple={true}
                          style={{ fontWeight: 500 }}
                          className="whiteText"
                          component={"span"}>
                          {getLocalizedString("selectAnnex")}
                        </Button>
                      </label>
                    </Grid>
                    <Grid item>
                      <Typography variant={"caption"} className={"field whiteText"}>
                        {
                          values.fileName !== ""
                            ? values.fileName + "." + values.fileExtension
                            : getLocalizedString("noAnnexYet")
                        }
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <ButtonDialogAction onClick={handleClose} text={getLocalizedString("disagree")} />
              <ButtonDialogAction type="submit" isAccept text={getLocalizedString("agree")} />
            </DialogActions>
          </form>
        )}
      />
    </StyledDialog>
  );
}

export default CreateEditDialog;
