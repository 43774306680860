import { useState, useMemo, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getAnnexesTypes,
  getInstances,
  getLoading,
  getAnnexesRows,
  getUserDates,
  getColabModifiableDocuments,
} from "../selectors";
import { createDateFromDDMMYYYY } from "@icarius-utils/date";
import { getLocalizedString } from "@icarius-localization/strings";
import { openSnackbarAction } from "@icarius-common/snackbar/actions";
import useAnnexYear from "./useAnnexYear";
import { previewFromURL } from "@icarius-utils/download";
import { BASE_URL } from "@icarius-connection/endpoints";
import { getFileExtension } from "@icarius-utils/fileUpload";
import { openDialogAction } from "@icarius-common/dialog/actions";
import {
  clearAnnexesFromStore,
  deleteAnnexFromServer,
  downloadAnnexFolderInServer,
  downloadAnnexInServer,
  getAnnexesRowsFromServer,
} from "@icarius-pages/annexesPage/actions";
import { getIsAdmin } from "src/app/selectors";

const useAnnexes = (code) => {

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAnnexesRowsFromServer(code));

    return () => {
      dispatch(clearAnnexesFromStore());
    }
  }, [dispatch, code])

  const [annex, setAnnex] = useState(null);
  const [downloadMenuIsOpen, setDownloadMenuIsOpen] = useState(false);
  const [treeViewIsOpen, setTreeViewIsOpen] = useState(false);
  const [createDialogIsOpen, setCreateDialogIsOpen] = useState(false);
  const [editDialogIsOpen, setEditDialogIsOpen] = useState(false);
  const [filterIsOff, setFilterIsOff] = useState(true);
  const [listInstance, setListInstance] = useState(null);
  const [typeDialogIsOpen, setTypeDialogIsOpen] = useState(null);
  const [exists, setExists] = useState(null);
  const isAdmin = useSelector(getIsAdmin);

  const handleGrouping = (gridRef) => {
    if (!treeViewIsOpen) {
      gridRef.columnApi.applyColumnState({
        state: [
          {
            aggFunc: null,
            colId: "ag-Grid-AutoColumn",
            flex: null,
            hide: false,
            pivot: false,
            pivotIndex: null,
            rowGroup: false,
            rowGroupIndex: null,
            sort: "asc",
            sortIndex: 0,
            width: 400,
          },
          {
            colId: 'Instancia string',
            rowGroup: true,
          },
          {
            colId: 'Tipo string',
            rowGroup: true,
          },
          {
            colId: 'Nombre archivo',
            rowGroup: true,
          },
        ]
      });
      gridRef.api.expandAll();
    } else {
      gridRef.columnApi.applyColumnState({
        defaultState: {
          // important to say 'null' as undefined means 'do nothing'
          hide: false,
          sort: null,
          rowGroup: null,
          pivot: null,
          pinned: null
        }
      });
    }
    setTreeViewIsOpen(prev => !prev);
  }

  const handleOpenCreateDialog = () => {
    setAnnex(null);
    setCreateDialogIsOpen(true);
  }

  const handleCloseCreateDialog = () => {
    setAnnex(null);
    setCreateDialogIsOpen(false);
  }

  const handleOpenEditDialog = (gridRef) => {
    let documents = gridRef.api.getSelectedRows();
    if (documents.length !== 1) {
      dispatch(openSnackbarAction({ msg: 'Debe seleccionar un registro', severity: "warning" }));
      return;
    }

    if (!isAdmin && documents[0]["editar"] === "N") {
      dispatch(openSnackbarAction({ msg: 'El registro seleccionado no se puede editar', severity: "warning" }));
      return;
    }

    if (documents[0]["Condicion"] === "F") {
      dispatch(openSnackbarAction({ msg: 'No es posible editarlo porque ha sido firmado digitalmente', severity: "warning" }));
      return;
    }
    
    setAnnex(documents[0]);
    setEditDialogIsOpen(true);
  }

  const handleCloseEditDialog = () => {
    setEditDialogIsOpen(false);
    setAnnex(null);
  }

  const handleOpenDeleteDialog = (gridRef) => {
    let documents = gridRef.api.getSelectedRows();
    if (documents.length !== 1) {
      dispatch(openSnackbarAction({ msg: 'Debe seleccionar un registro', severity: "warning" }));
      return;
    }

    if (!isAdmin && documents[0]["editar"] === "N") {
      dispatch(openSnackbarAction({ msg: 'El registro seleccionado no se puede eliminar', severity: "warning" }));
      return;
    }

    if (documents[0]["Condicion"] === "F") {
      dispatch(openSnackbarAction({ msg: 'No es posible eliminarlo porque ha sido firmado digitalmente', severity: "warning" }));
      return;
    }

    dispatch(openDialogAction({
      title: getLocalizedString("atention"),
      msg: getLocalizedString("annexConfirm"),
      onConfirm: () => dispatch(deleteAnnexFromServer(documents[0]['Codigo'])),
    }));
  }

  const handleOpenDownloadMenu = (e) => {
    setDownloadMenuIsOpen(e.currentTarget);
  }

  const handleCloseDownloadMenu = () => {
    setDownloadMenuIsOpen(false);
  }

  const handleOpenDownloadDialog = (gridRef, dataToSend) => {
    // descarga la carpeta entera
    if (!Boolean(gridRef)) {
      handleDownload(dataToSend);
      return;
    }

    // descarga los seleccionados
    let documents = gridRef.api.getSelectedRows();
    if (documents.length === 0) {
      dispatch(openSnackbarAction({ msg: 'Debe seleccionar al menos un registro', severity: "warning" }));
      return;
    }

    handleDownload({ ...dataToSend, annexCodes: documents.map(item => item.Codigo) });
  }

  const handleDownload = (dataToSend) => {
    const getMessage = () => {
      if (dataToSend['Codigo'] && !dataToSend?.ignoreHidden && !dataToSend.annexCodes) return getLocalizedString("downloadAnnexFolderConfirm");
      if (dataToSend['Codigo'] && dataToSend?.ignoreHidden && !dataToSend.annexCodes) return '¿Desea descargar la carpeta sin los archivos ocultos?';
      if (dataToSend.annexCodes.length > 1) return '¿Desea descargar los archivos seleccionados?';
      return '¿Desea descargar el archivo?';
    }

    setDownloadMenuIsOpen(false);
    dispatch(openDialogAction({
      msg: getMessage(),
      title: 'Atención',
      onConfirm: () => {
        dataToSend?.annexCodes?.length === 1
          ? dispatch(downloadAnnexInServer(dataToSend.annexCodes[0]))
          : dispatch(downloadAnnexFolderInServer(dataToSend))
      },
    }));
  }

  const handleOpenTypeDialog = () => {
    setTypeDialogIsOpen(true);
  };

  const handleCloseTypeDialog = () => {
    setTypeDialogIsOpen(false);
  };

  const handleOpenInstanceDialog = (instance, index) => {
    setListInstance(instance);
    setExists(index);
  };

  const handleCloseInstanceDialog = () => {
    setListInstance(null);
    setExists(false);
  };

  const handlePreview = (gridRef) => {
    let documents = gridRef.api.getSelectedRows();
    if (documents.length !== 1) {
      dispatch(openSnackbarAction({ msg: 'Debe seleccionar un registro', severity: "warning" }));
      return;
    }

    if (getFileExtension(documents[0]["url"]) !== "pdf") {
      dispatch(openSnackbarAction({ msg: 'El archivo seleccionado no tiene formato .pdf', severity: "warning" }));
      return;
    }

    previewFromURL(BASE_URL + documents[0]["url"]);
  }

  const colabModifiableDocuments = useSelector(getColabModifiableDocuments);
  const isLoading = useSelector(getLoading);
  const types = useSelector(getAnnexesTypes);
  const instances = useSelector(getInstances);
  const data = useSelector(getAnnexesRows);
  const userDates = useSelector(getUserDates);

  const { year, setYear, yearsArray } = useAnnexYear(data);

  const setAnnexesTypeToShow = (tipo) => {
    switch (exists) { //0 existe, 1 no existe obligatorio, 2 no existe no obligatorio
      case 0: return tipo.exists > 0;
      case 1: return tipo.exists === 0 && tipo.required === "Y"
      case 2: return tipo.exists === 0 && tipo.required === "N"
      default: return
    }
  };

  const getAnnexTypeName = (instance, typeToMatch) => {
    let typeFound = types[instance].find(type => type.key === typeToMatch);
    return typeFound ? typeFound.value : getLocalizedString("annexNoType");
  };

  const getAnnexInstanceName = (instanceToMatch) => {
    return instances.find(instance => instance.key === instanceToMatch).value;
  };

  let arrayToRender = useMemo(() => {
    let aux = [...data];

    //filtrar segun año seleccionado
    if (year !== "") {
      aux = aux.filter(annex => {
        const annexYear = createDateFromDDMMYYYY(annex["Fecha de registro"]).getFullYear();
        const formattedSelectedYear = parseInt(year);
        return annexYear === formattedSelectedYear;
      })
    }

    if (!filterIsOff) {
      aux = aux.filter(annex => createDateFromDDMMYYYY(annex["Fecha de registro"]) >= createDateFromDDMMYYYY(userDates["Fecha contratacion"]))
    }

    return aux;
  }, [year, filterIsOff, data, userDates])

  const state = {
    annex,
    createDialogIsOpen,
    editDialogIsOpen,
    filterIsOff,
    listInstance,
    typeDialogIsOpen,
    exists,
    treeViewIsOpen,
    downloadMenuIsOpen,
  }

  const handlers = {
    handleOpenCreateDialog,
    handleCloseCreateDialog,
    handleOpenEditDialog,
    handleCloseEditDialog,
    handleOpenDeleteDialog,
    handleOpenDownloadDialog,
    handleOpenTypeDialog,
    handleCloseTypeDialog,
    handleOpenInstanceDialog,
    handleCloseInstanceDialog,
    setFilterIsOff,
    handlePreview,
    handleGrouping,
    handleOpenDownloadMenu,
    handleCloseDownloadMenu,
  }

  return {
    state,
    handlers,
    arrayToRender,
    isLoading,
    types,
    instances,
    colabModifiableDocuments,
    userDates,
    year,
    setYear,
    yearsArray,
    setAnnexesTypeToShow,
    getAnnexTypeName,
    getAnnexInstanceName,
  };
}

export default useAnnexes;
