import NAME from "./constants";

export const GET_ROWS = NAME + "/GET_ROWS";
export const GET_ROWS_FULFILLED = NAME + "/GET_ROWS_FULFILLED";
export const GET_ROWS_REJECTED = NAME + "/GET_ROWS_REJECTED";

export const GET_PROCESSES = NAME + "/GET_PROCESSES";
export const GET_PROCESSES_FULFILLED = NAME + "/GET_PROCESSES_FULFILLED";
export const GET_PROCESSES_REJECTED = NAME + "/GET_PROCESSES_REJECTED";

export const CLEAR_STATE = NAME + "/CLEAR_STATE";