import { getLocalizedString } from "@icarius-localization/strings"
import { material, categoria, meritoDemerito } from "@icarius-utils/colors";

const getAnnotationChartData = (data, getSubtypeName) => {  

  const getCountArray = (itemsArray) => {
    const countArray = itemsArray.reduce((prev, current) => {
      return (prev[current] ? prev[current]++ : prev[current] = 1, prev)
    }, [])
    return countArray;
  }

  const getChartData = (itemsArray) => {
    const countArray = getCountArray(itemsArray);
    return Object.keys(countArray).map((item, index) => {
      return (
        {
          text: item,
          value: Object.values(countArray)[index],
        }
      )
    }).sort((a, b) => b.value - a.value);
  }

  const fillDataStructure = (labels, values, color, title) => {
    return {
      labels: labels,
      datasets: [
        {
          data: values,
          backgroundColor: color.fills,
          borderColor: color.strokes,
          borderWidth: 1,
        },
      ],
      title: title,
    }
  }

  const createDataForAnnotationSubtypeChart = () => {
    const subtypesInUse = [];
    const labels = [];
    const values = [];

    if (data?.length > 0) {
      data.forEach(annotation => {
        let subtipo = annotation["Sub"];
        let tipo = annotation["Tipo"];
        let subtipoNombre = getSubtypeName(tipo, subtipo);

        subtypesInUse.push(subtipoNombre);
      });

      const chartData = getChartData(subtypesInUse);

      chartData.forEach(dataItem => {
        labels.push(dataItem.text);
        values.push(dataItem.value);
      })

      return fillDataStructure(labels, values, material, getLocalizedString("subtypeTitle"));
    }
  };

  const createDataForAnnotationTypeChart = () => {
    if (data?.length > 0) {
      let cantMerito = data.filter(item => item["Tipo"] === "ME").length || 0;
      let cantDemerito = data.filter(item => item["Tipo"] === "DE").length || 0;
      let sinTipo = data.length - cantMerito - cantDemerito;

      return fillDataStructure(
        ['Mérito', 'Demérito', 'Otras'],
        [cantMerito, cantDemerito, sinTipo],
        meritoDemerito,
        getLocalizedString("meritsTitle")
      );
    }
  };

  const createDataForCategoryChart = () => {
    if (data?.length > 0) {
      let cantVerde = data.filter(item => item["Categoria"] === "V").length || 0;
      let cantRojo = data.filter(item => item["Categoria"] === "R").length || 0;
      let cantAmarillo = data.filter(item => item["Categoria"] === "A").length || 0;
      let sinCategoria = data.length - cantVerde - cantRojo - cantAmarillo;

      return fillDataStructure(
        ['Rojo', 'Amarillo', 'Verde', 'Sin categoría'],
        [cantRojo, cantAmarillo, cantVerde, sinCategoria],
        categoria,
        getLocalizedString("categoryTitle")
      );
    }
  };

  return [
    createDataForAnnotationTypeChart(),
    createDataForAnnotationSubtypeChart(),
    createDataForCategoryChart(),
  ];
}

export default getAnnotationChartData;