import React, { useContext } from "react";
import {
  DialogContent,
  DialogActions,
  Switch,
  FormControlLabel,
  Grid,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  TextField,
} from "@material-ui/core";
import { MuiPickersUtilsProvider, DatePicker, TimePicker } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import esLocale from "date-fns/locale/es";
import { getLocalizedString } from "@icarius-localization/strings";
import ButtonDialogAction from "@icarius-common/buttonDialogAction";
import { useSelector, useDispatch } from "react-redux";
import { getCategories, getAnnotationTypes, getSubtypes } from "@icarius-pages/annotationsPage/selectors";
import { EmployeeContext } from "@icarius-pages/annotationsPage/components/index";
import { openSnackbarAction } from "@icarius-common/snackbar/actions";
import useHandleForm from "./useHandleForm";
import FileInput from "@icarius-common/fileUpload/fileInput";
import FileList from "@icarius-common/fileUpload/fileList";

const gridStyle = { height: 75, padding: "0px 10px" };

const NewAnnotationContent = (props) => {

  const {
    data,
    type,
    handleClose,
    handleProcess,
  } = props;

  const dispatch = useDispatch();
  const employee = useContext(EmployeeContext);
  const categories = useSelector(getCategories);
  const types = useSelector(getAnnotationTypes);
  const subtypes = useSelector(getSubtypes);

  const openValidationError = () => {
    dispatch(openSnackbarAction({ msg: 'Hay datos invalidos, y la fecha y hora no pueden superar a la fecha y hora actual', severity: "error" }));
  }

  const callback = (dataToSend) => {
    handleProcess(dataToSend);
    handleClose();
  }

  const {
    formData,
    setFormValue,
    submit,
  } = useHandleForm(data, type, employee, callback, openValidationError);

  const subIsDisabled = !formData['Tipo'] || formData['Tipo'] === 'OT';

  return (
    <DialogContent>
      <Grid container item xs={12}>
        <Grid container item alignItems="center" xs={12} sm={6} style={gridStyle}>
          <FormControl style={{ width: "100%" }}>
            <InputLabel required id={`label-type`}>{"Tipo"}</InputLabel>
            <Select
              labelId={`label-Tipo`}
              id={`select-Tipo`}
              value={formData['Tipo']}
              onChange={(e) => setFormValue(e.target.value, "Tipo")}
              margin={"none"}
            >
              {
                types?.map(item => (
                  <MenuItem
                    className={"whiteText"}
                    key={item.FldValue}
                    value={item.FldValue}
                  >
                    {item.Descr}
                  </MenuItem>
                ))
              }
            </Select>
          </FormControl>
        </Grid>
        <Grid container item alignItems="center" xs={12} sm={6} style={gridStyle}>
          <FormControl style={{ width: "100%" }}>
            <InputLabel required={!subIsDisabled} id={`label-type`}>{"Subtipo"}</InputLabel>
            <Select
              disabled={subIsDisabled}
              labelId={`label-Subtipo`}
              id={`select-Subtipo`}
              value={formData['Sub']}
              onChange={(e) => setFormValue(e.target.value, "Sub")}
              margin={"none"}
            >
              {
                formData['Tipo'] && subtypes[formData['Tipo']]?.map(item => (
                  <MenuItem
                    className={"whiteText"}
                    key={item.FldValue}
                    value={item.FldValue}
                  >
                    {item.Descr}
                  </MenuItem>
                ))
              }
            </Select>
          </FormControl>
        </Grid>
        <Grid container item alignItems="center" xs={12} sm={6} md={4} style={gridStyle}>
          <FormControl style={{ width: "100%" }}>
            <InputLabel required id={`label-type`}>{"Categoría"}</InputLabel>
            <Select
              labelId={`label-Categoria`}
              id={`select-Categoria`}
              value={formData['Categoria']}
              onChange={(e) => setFormValue(e.target.value, "Categoria")}
              margin={"none"}
            >
              {
                categories?.map(item => (
                  <MenuItem
                    className={"whiteText"}
                    key={item.FldValue}
                    value={item.FldValue}
                  >
                    {item.Descr}
                  </MenuItem>
                ))
              }
            </Select>
          </FormControl>
        </Grid>
        <Grid container item alignItems="center" xs={12} sm={6} md={4} style={gridStyle}>
          <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
            <DatePicker
              fullWidth
              required
              label={'Fecha de registro'}
              cancelLabel={getLocalizedString("cancel")}
              okLabel={getLocalizedString("ok")}
              invalidDateMessage=""
              maxDate={new Date()}
              format="dd/MM/yyyy"
              margin="none"
              value={formData['fechaRegistro']}
              onChange={(e) => setFormValue(e, 'fechaRegistro')}
            />
          </MuiPickersUtilsProvider>
        </Grid>
        <Grid container item alignItems="center" xs={12} sm={6} md={4} style={gridStyle}>
          <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
            <TimePicker
              required
              label={'Hora de registro'}
              value={formData['horaRegistro']}
              onChange={(e) => setFormValue(e, 'horaRegistro')}
              minutesStep={5}
              fullWidth
              clearable
              clearLabel={getLocalizedString("clear")}
              cancelLabel={getLocalizedString("cancel")}
              okLabel={getLocalizedString("ok")}
              invalidDateMessage=""
              minDateMessage=""
              format="HH:mm"
              margin="none"
              ampm={false}
            />
          </MuiPickersUtilsProvider>
        </Grid>
        <Grid container item alignItems="center" xs={12} style={gridStyle}>
          <TextField
            required
            fullWidth
            margin={"none"}
            label={'Descripción'}
            onChange={(e) => setFormValue(e.target.value, "descripcion")}
            value={formData['descripcion']}
            inputProps={{ maxLength: 512 }}
          />
        </Grid>
        <Grid container item alignItems="center" xs={12} style={gridStyle}>
          <FormControlLabel
            control={
              <Switch
                checked={formData['switchVisibility']}
                onChange={() => setFormValue(!formData.switchVisibility, "switchVisibility")}
                value={formData['switchVisibility']}
                color="primary"
              />
            }
            label={getLocalizedString("visibleColaborador")}
            labelPlacement="start"
            style={{ marginLeft: 0 }}
          />
        </Grid>
        <Grid container item alignItems="center" xs={12} style={{ ...gridStyle, height: '' }}>
          <FileInput
            acceptedFormats={['.jpg', '.bmp', '.png', '.jpeg', '.rar', '.zip', '.gz', '.tar', '.xls', '.xlsx', '.txt', '.rtf', '.doc', '.docx', '.pdf', '.html', '.ppt', '.pptx']}
            handleChange={(files) => setFormValue(files, 'file')}
          />
          <FileList
            files={formData.file}
            handleClick={() => setFormValue(null, 'file')}
          />
        </Grid>
      </Grid>
      <DialogActions>
        <ButtonDialogAction onClick={handleClose} text={getLocalizedString("disagree")} />
        <ButtonDialogAction onClick={submit} isAccept text={getLocalizedString("agree")} />
      </DialogActions>
    </DialogContent>
  );
}

export default NewAnnotationContent;