import paths from "@icarius-localization/paths";
import { getLocalizedString } from "@icarius-localization/strings";
import {
    dateClassRule,
    dateFormatter,
    dateValueGetter,
    dateFilterParams,
    dateComparator,
} from "@icarius-table/date";

export default [
    {
        pages: [paths.annotationsAnalysis],
        config: {
            headerName: getLocalizedString("employeeCode"),
            field: "code",
            filter: "agTextColumnFilter",
            chartDataType: "series",
            checkboxSelection: function (params) {
                return params.columnApi.getRowGroupColumns().length === 0;
            },
            headerCheckboxSelection: function (params) {
                return params.columnApi.getRowGroupColumns().length === 0;
            },
            headerCheckboxSelectionFilteredOnly: true,
            enableRowGroup: true,
        }
    },
    {
        pages: [paths.annotationsAnalysis],
        config: {
            headerName: getLocalizedString("nameAndSurname"),
            field: "name",
            filter: "agTextColumnFilter",
        }
    },
    {
        pages: [paths.annotationsAnalysis],
        config: {
            headerName: getLocalizedString("date"),
            field: "date",
            filter: "agTextColumnFilter",
            comparator: dateComparator,
            filterParams: dateFilterParams,
            cellClassRules: dateClassRule,
            valueFormatter: dateFormatter,
            valueGetter: dateValueGetter,
            cellClass: "dateFormat",
        }
    },
    {
        pages: [paths.annotationsAnalysis],
        config: {
            headerName: getLocalizedString("hour"),
            field: "time",
            filter: "agTextColumnFilter",
        }
    },
    {
        pages: [paths.annotationsAnalysis],
        config: {
            headerName: getLocalizedString("type"),
            field: "type",
            filter: "agSetColumnFilter",
            cellRenderer: "TypeRenderer",
        }
    },
    {
        pages: [paths.annotationsAnalysis],
        config: {
            headerName: getLocalizedString("subtype"),
            field: "subtype",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: [paths.annotationsAnalysis],
        config: {
            headerName: getLocalizedString("category"),
            field: "category",
            filter: "agSetColumnFilter",
            cellRenderer: "CategoryRenderer",
        }
    },
    {
        pages: [paths.annotationsAnalysis],
        config: {
            headerName: getLocalizedString("annoText"),
            field: "text",
            filter: "agTextColumnFilter",
        }
    },
    {
        pages: [paths.annotationsAnalysis],
        config: {
            headerName: getLocalizedString("responsible"),
            field: "responsible",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: [paths.annotationsAnalysis],
        config: {
            headerName: 'Evidencia',
            field: "fileName",
            filter: "agTextColumnFilter",
        }
    },
]