import React from "react";
import { CloseIcon, CheckIcon } from "@icarius-icons";
import { withStyles } from "@material-ui/core/styles";
import { List, Dialog, DialogTitle, DialogContent, ListItem, Grid } from "@material-ui/core";
import AnnotationText from "@icarius-pages/annotationsPage/components/annotations/annotationText";
import { getLocalizedString } from "@icarius-localization/strings";
import { annexGraph } from "@icarius-utils/colors";
import DialogTitleDivider from "@icarius-common/dialogTitleDivider";
import PaperDraggable from "@icarius-common/paperDraggable";
import DialogTransition from "@icarius-common/dialogTransition";

const StyledDialog = withStyles({
  paper: {
    maxWidth: "65%",
    minWidth: "65%",
  },
})(Dialog);

const CustomListItem = withStyles({
  root: {
    paddingLeft: 0,
  },
})(ListItem);

const InstanceListDialog = (props) => {

  const { open, types, instance, exists, getAnnexTypeName, handleClose } = props;

  return (
    <StyledDialog
      TransitionComponent={DialogTransition}
      PaperComponent={PaperDraggable}
      open={open}
      scroll={"paper"}
      fullWidth={true}
      maxWidth={"lg"}
    >
      <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
        {getLocalizedString("documentsListTitle")}
        <DialogTitleDivider />
      </DialogTitle>
      <CloseIcon className={"dialog-close-icon icon"} onClick={handleClose} />
      <DialogContent style={{ paddingTop: 0, paddingBottom: "20px" }}>
        <List className="p-0 h-100 backgroundSecondary listAnnotations">
          {
            types.map((type, index) => {
              const color = annexGraph.fills[exists];

              return (
                <CustomListItem
                  key={index}
                  style={{ cursor: "auto" }}
                  className="py-16  px-0 sm:pr-8 listItemBorderBottom whiteText"
                  dense
                  disableGutters
                  button>
                  <Grid container>
                    <Grid item xs={10}>
                      <div className="flex flex-1 flex-col relative overflow-hidden">
                        <Grid style={{ paddingLeft: 30 }} container direction="row">
                          <Grid item xs={1} md={1} sm={1}>
                            <AnnotationText text={type.exists ? <CheckIcon htmlColor={color} /> : <CloseIcon htmlColor={color} />} />
                          </Grid>
                          <Grid item xs={8} md={8} sm={8}>
                            <AnnotationText text={getAnnexTypeName(instance, type.key)} />
                          </Grid>
                          <Grid item xs={3} md={3} sm={3}>
                            <AnnotationText text={type.required === "Y" ? getLocalizedString("annexRequired") : ""} />
                          </Grid>
                        </Grid>
                      </div>
                    </Grid>
                  </Grid>
                </CustomListItem>
              )
            })
          }
        </List>
      </DialogContent>
    </StyledDialog>
  );
}

export default InstanceListDialog;
