import { useState, useMemo } from "react";
import { createDateFromDDMMYYYY } from "@icarius-utils/date";

const useAnnotationYear = (data, initialYear) => {

  const [year, setYear] = useState(() => {
    if (!initialYear) return "";

    const yearExists = data.some((item) => createDateFromDDMMYYYY(item["Fecha de registro"]).getFullYear() === initialYear);

    if (yearExists) return initialYear;
    return "";
  });

  const yearsArray = useMemo(() => {
    const yearsArrayAux = [];

    data.forEach((annotation) => {
      const annotationYear = createDateFromDDMMYYYY(annotation["Fecha de registro"]).getFullYear();

      if (!Boolean(yearsArrayAux.find((year) => year.key === annotationYear))) {
        yearsArrayAux.push({ key: annotationYear, value: annotationYear });
      }
    })

    return yearsArrayAux;
  }, [data])

  return {
    year,
    setYear,
    yearsArray,
  }
}

export default useAnnotationYear;
